<aside [class.opened]="filterAsideOpened" class="filter-card {{ filterAsidePined ? 'pined' : null }} {{ classList }}">
  <i *ngIf="!filterAsidePined" (click)="filterAsideOpened = true" data-action="open" class="icon icon-search c-pointer"> </i>
  <header class="justify-content-between gap-2 d-flex">
    <div class="align-items-center gap-2 d-flex">
      <i *ngIf="!filterAsidePined" (click)="filterAsideOpened = false" data-action="close" class="bx bx-chevron-right c-pointer"> </i>
      <i class="icon icon-filter mx-0"> </i>
      <span> {{ 'FILTERS' | translate }} </span>
    </div>
    <div class="btn-action align-items-center gap-2 d-flex">
      <i (click)="onClickPinBtn()" data-action="pin" class="bx bx-pin d-none d-md-block"> </i>
      <i (click)="removeFilters.emit()" class="bx bx-trash"> </i>
    </div>
  </header>
  <ng-content> </ng-content>
</aside>
