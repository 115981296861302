import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { of, forkJoin } from 'rxjs';
import { map, mergeMap, concatMap, shareReplay, catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { TranslatePipe } from 'src/app/core/pipes/translate.pipe';
import { FullNamePipe } from 'src/app/core/pipes/full-name.pipe';
import { PricePipe } from 'src/app/core/pipes/price.pipe';
import { GroupByPipe } from 'src/app/core/pipes/group-by.pipe';
import { ApiService } from 'src/app/core/services/api.service';
import { AccountingService } from 'src/app/core/api/accounting.service';
import { AccountService } from 'src/app/core/api/account.service';
import { FamilyService } from 'src/app/core/api/family.service';
import { PrintService } from 'src/app/core/services/print.service';
import { environment } from 'src/environments/environment';
import { SETTING_IDS, LENGTH_UNIT, WEIGHT_UNIT } from 'src/app/core/constants';
import { Ancestry, Gender, MaritalStatus, HealthStatuses, CureStatuses, QualityStatuses, EducationStatuses, AttachmentGroups } from 'src/app/core/models/enums';
import { User, UserCallNumberModel, UserSocialNetworkModel } from 'src/app/core/models/auth.models';
import { PublicModel, AddressModel } from 'src/app/core/models/public-model';
import { RefuseReason, CampaignModel } from 'src/app/core/models/settings-model';
import { FamilyMemberModel, FamilyRequestModel, FamilyUserHealthGet, FamilyUserDeathInfo, FamilyUserIllnessGet, FamilyUserHospitalizationGet,
  FamilyUserEducationInfo, FamilyUserEducationFilter, FamilyUserJobHistoryGet, FamilyUserSkillInfo, FamilyUserOtherInfo,
  FamilyCashNeedModel, FamilyGoodNeedModel, FamilyServiceNeedModel } from 'src/app/core/models/family-model';
import { BranchModel } from 'src/app/core/models/donation-financial-model';

interface MemberAllDataModel {
  info?: User;
  familyInfo?: FamilyRequestModel;
  addressInfo?: { home?: AddressModel, work?: AddressModel };
  callNumbersData?: UserCallNumberModel[];
  socialNetworksData?: UserSocialNetworkModel[];
  healthData?: FamilyUserHealthGet[];
  deathInfo?: FamilyUserDeathInfo;
  illnessData?: FamilyUserIllnessGet[];
  hospitalizationData?: FamilyUserHospitalizationGet[];
  educationInfo?: FamilyUserEducationInfo;
  educationData?: FamilyUserEducationFilter[];
  jobData?: FamilyUserJobHistoryGet[];
  skillData?: FamilyUserSkillInfo[];
  otherInfo?: FamilyUserOtherInfo;
  cashNeedData?: FamilyCashNeedModel[];
  goodNeedData?: FamilyGoodNeedModel[];
  serviceNeedData?: FamilyServiceNeedModel[];
  attachData?: any;
}

@Component({
  selector: 'family-member-print',
  templateUrl: 'family-member-print.component.html',
  styleUrls: ['family-member-print.component.scss']
})

export class FamilyMemberPrintComponent implements OnInit {

  env: string;
  selected: FamilyMemberModel;
  member: MemberAllDataModel;
  hasData: { HEALTH_INFO?: boolean, EDUCATIONAL_INFO?: boolean, JOB_INFO?: boolean, SKILLS_INFO?: boolean, ENTERTAINMENT_AND_WISHES_INFO?: boolean, NEEDS?: boolean, ATTACH?: boolean };
  printDate: string;
  loading: boolean;
  toastRef: any;

  constructor(private datePipe: DatePipe, private toastrService: ToastrService, public translatePipe: TranslatePipe, private fullNamePipe: FullNamePipe, private pricePipe: PricePipe,
              private groupByPipe: GroupByPipe, private apiService: ApiService, private accountingService: AccountingService, private accountService: AccountService,
              private familyService: FamilyService, private printService: PrintService) {}

  ngOnInit(): void {
    this.env = environment.api;
    this.selected = this.printService.mainData;
    this.hasData = { HEALTH_INFO: false, EDUCATIONAL_INFO: false, JOB_INFO: false, SKILLS_INFO: false, ENTERTAINMENT_AND_WISHES_INFO: false, NEEDS: false, ATTACH: false };
    this.printDate = this.datePipe.transform(new Date(), 'YYYY-MM-dd');
    this.loading = true;
    this.toastRef = this.toastrService.info(this.translatePipe.transform('PRINTING'), null, { timeOut: 3000, positionClass: 'toast-bottom-center' });
    const familyMemberIdentifier = this.selected.identifier;
    const userIdentifier = this.selected.userIdentifier;
    const pageSize = 500;
    const pagination = { skip: 0, take: pageSize };
    forkJoin({
      branch: this.accountingService.branchFindBranch<BranchModel[]>({}).pipe(map((res) => res.data)),
      healthTag: this.filterSettings<PublicModel[]>(SETTING_IDS.healthTags),
      educationTag: this.filterSettings<PublicModel[]>(SETTING_IDS.educationTags),
      dropoutReason: this.filterSettings<PublicModel[]>(SETTING_IDS.dropoutReasons),
      job: this.accountService.jobFilter<RefuseReason[]>({ pagination: { ...pagination, sortProperties: { title: 1 }} }).pipe(map((res) => res.data)),
      interest: this.filterSettings<PublicModel[]>(SETTING_IDS.interests),
      wish: this.filterSettings<PublicModel[]>(SETTING_IDS.wishes),
      hobby: this.filterSettings<PublicModel[]>(SETTING_IDS.hobbies),
      talent: this.filterSettings<PublicModel[]>(SETTING_IDS.talents),
      donationTree: this.filterSettings<CampaignModel[]>(SETTING_IDS.donationTree),
      good: this.filterSettings<PublicModel[]>(SETTING_IDS.products),
      service: this.filterSettings<PublicModel[]>(SETTING_IDS.service)
    }).pipe(mergeMap((pre) => {
      return forkJoin({
        info: this.accountService.userGetByIdentifier<User>({}, userIdentifier).pipe(map((res) => {
          return this.infoMapper(pre, res.data);
        }), concatMap((data) => {
          return this.familyService.userOtherInfoGet<FamilyUserOtherInfo>({ familyMemberIdentifier }).pipe(map((r) => {
            return r.data ? { ...data, ...this.otherInfoMapper(pre, r.data) } : data;
          }));
        })),
        familyInfo: this.familyService.familyGetByIdentifiers<FamilyRequestModel>({ identifiers: [this.selected.familyIdentifier] }).pipe(map((res) => this.familyInfoMapper(pre, res.data[0]))),
        addressInfo: this.accountService.userGetAddresses<AddressModel[]>({ userIdentifier }).pipe(map((res) => res.data && res.data.length ? this.addressInfoMapper(pre, res.data) : null)),
        callNumbersData: this.accountService.userCallNumberGetByUserIdentifier<UserCallNumberModel[]>({}, userIdentifier).pipe(map((res) => res.data && res.data.length ? res.data : [{}])),
        socialNetworksData: this.accountService.userSocialNetworkGetByUserIdentifier<UserSocialNetworkModel[]>({}, userIdentifier).pipe(map((res) => res.data && res.data.length ? res.data : [{}])),
        healthData: this.familyService.userHealthGet<FamilyUserHealthGet[]>({ familyMemberIdentifier }).pipe(map((res) => res.data && res.data.length ? this.healthDataMapper(pre, res.data) : [{}])),
        deathInfo: this.familyService.userOtherInfoGetUserDeath<FamilyUserDeathInfo>({ familyMemberIdentifier }).pipe(map((res) => res.data ? this.deathInfoMapper(pre, res.data) : null)),
        illnessData: this.familyService.userIllnessGet<FamilyUserIllnessGet[]>({ familyMemberIdentifier }).pipe(map((res) => res.data && res.data.length ? this.illnessDataMapper(pre, res.data) : [{}])),
        hospitalizationData: this.familyService.userHospitalizationGet<FamilyUserHospitalizationGet[]>({ familyMemberIdentifier }).pipe(map((res) => res.data && res.data.length ? this.hospitalizationDataMapper(pre, res.data) : [{}])),
        educationInfo: this.familyService.userEducationGetByFamilyMember<FamilyUserEducationInfo>({ familyMemberIdentifier }).pipe(map((res) => res.data ? this.educationInfoMapper(pre, res.data) : null)),
        educationData: this.familyService.userEducationHistoryFilter<FamilyUserEducationFilter[]>({ familyMemberIdentifier }).pipe(map((res) => res.data && res.data.length ? this.educationDataMapper(pre, res.data) : [{}])),
        jobData: this.familyService.userJobHistoryGet<FamilyUserJobHistoryGet[]>({ familyMemberIdentifier }).pipe(map((res) => res.data && res.data.length ? this.jobDataMapper(pre, res.data) : [{}])),
        skillData: this.familyService.userSkillGet<FamilyUserSkillInfo[]>({ familyMemberIdentifier }).pipe(map((res) => res.data && res.data.length ? this.skillDataMapper(pre, res.data) : [{}])),
        otherInfo: this.familyService.userOtherInfoGet<FamilyUserOtherInfo>({ familyMemberIdentifier }).pipe(map((res) => res.data ? this.otherInfoMapper(pre, res.data) : null)),
        cashNeedData: this.familyService.familyCashAidFilter<FamilyCashNeedModel[]>({ familyMemberIdentifier, pagination: { ...pagination, sortProperties: { requestDate: 1 }} }).pipe(map((res) => {
          return res.data && res.data.length ? this.cashNeedDataMapper(pre, res.data) : [{}];
        })),
        goodNeedData: this.familyService.familyObjectiveAidFilter<FamilyGoodNeedModel[]>({ familyMemberIdentifier, pagination: { ...pagination, sortProperties: { requestDate: 1 }} }).pipe(map((res) => {
          return res.data && res.data.length ? this.goodNeedDataMapper(pre, res.data) : [{}];
        })),
        serviceNeedData: this.familyService.familyServiceAidFilter<FamilyServiceNeedModel[]>({ familyMemberIdentifier, pagination: { ...pagination, sortProperties: { requestDate: 1 }} }).pipe(map((res) => {
          return res.data && res.data.length ? this.serviceNeedDataMapper(pre, res.data) : [{}];
        })),
        attachData: this.familyService.familyMemberAttachmentGet<any>({ familyMemberIdentifier }).pipe(map((res) => res.data && res.data.length ? this.attachDataMapper(pre, res.data) : [{}]))
      }).pipe(map((data) => {
        this.member = { ...data };
        this.hasData = {
          HEALTH_INFO: (data.healthData && data.healthData.length) || data.deathInfo || (data.illnessData && data.illnessData.length) || (data.hospitalizationData && data.hospitalizationData.length) || (data.attachData && data.attachData.health),
          EDUCATIONAL_INFO: data.educationInfo || (data.educationData && data.educationData.length) || (data.attachData && data.attachData.education),
          JOB_INFO: data.jobData && data.jobData.length,
          SKILLS_INFO: data.skillData && data.skillData.length,
          ENTERTAINMENT_AND_WISHES_INFO: data.otherInfo,
          NEEDS: (data.cashNeedData && data.cashNeedData.length) || (data.goodNeedData && data.goodNeedData.length) || (data.serviceNeedData && data.serviceNeedData.length),
          ATTACH: data.attachData && data.attachData.all && data.attachData.all.length
        };
        console.log(this.member);
        this.loading = false;
        this.toastrService.clear(this.toastRef.ToastId);
        setTimeout(() => window.print(), 1000);
      }));
    })).subscribe();
  }

  filterSettings<T>(identifier: string) {
    return this.apiService.filterSettings<T>({ identifier }).pipe(map((res) => res.data), shareReplay(), catchError((err) => of(err)));
  }

  infoMapper(pre: any, data: any) {
    data.familyCode = this.selected.familyCode;
    data.fullName = this.fullNamePipe.transform(data);
    data.birthDate = this.datePipe.transform(data.birthDate, 'YYYY-MM-dd');
    data.ancestry = this.translatePipe.transform(Ancestry[data.ancestry]);
    data.gender = this.translatePipe.transform(Gender[data.gender]);
    data.maritalStatus = this.translatePipe.transform(MaritalStatus[data.maritalStatus]);
    return data;
  }

  familyInfoMapper(pre: any, data: any) {
    data.branch = pre.branch.find((item: BranchModel) => item.id === data.branchId)?.title;
    data.firstFinalConfirm = this.datePipe.transform(data.firstFinalConfirm, 'YYYY-MM-dd');
    data.lastFinalConfirm = this.datePipe.transform(data.lastFinalConfirm, 'YYYY-MM-dd');
    return data;
  }

  addressInfoMapper(pre: any, data: any) {
    let addresses: any = {};
    addresses.home = data.find((item: AddressModel) => item.addressType === 0) || null;
    addresses.work = data.find((item: AddressModel) => item.addressType === 1) || null;
    return addresses;
  }

  healthDataMapper(pre: any, data: any) {
    data.map((item: FamilyUserHealthGet) => {
      item.healthStatus = this.translatePipe.transform(HealthStatuses[item.healthStatus]);
      item.height = item.height + ' ' + this.translatePipe.transform(LENGTH_UNIT[0].name);
      item.weight = item.weight + ' ' + this.translatePipe.transform(WEIGHT_UNIT[0].name);
      item.waistSize = item.waistSize + ' ' + this.translatePipe.transform(LENGTH_UNIT[0].name);
      item.tagsAll = '';
      if (item.tags && item.tags.length) {
        item.tags.map((tag: number) => {
          const title = pre.healthTag.find((item: PublicModel) => item.Id === tag)?.Title;
          item.tagsAll += this.translatePipe.transform(title) + ', ';
        });
      }
      item.visualLevel = this.translatePipe.transform(HealthStatuses[item.visualLevel]);
      item.hearingLevel = this.translatePipe.transform(HealthStatuses[item.hearingLevel]);
      item.pronunciationLevel = this.translatePipe.transform(HealthStatuses[item.pronunciationLevel]);
      item.createDate = this.datePipe.transform(item.createDate, 'YYYY-MM-dd');
    });
    return data;
  }

  deathInfoMapper(pre: any, data: any) {
    data.dateOfDeathCertificate = this.datePipe.transform(data.dateOfDeathCertificate, 'YYYY-MM-dd');
    data.date = this.datePipe.transform(data.date, 'YYYY-MM-dd');
    return data;
  }

  illnessDataMapper(pre: any, data: any) {
    data.map((item: FamilyUserIllnessGet) => {
      switch (item.isCured) {
        case true: item.isCured = this.translatePipe.transform(CureStatuses[0]); break;
        case false: item.isCured = this.translatePipe.transform(CureStatuses[1]); break;
        default: item.isCured = null; break;
      }
      item.illnessCost = this.pricePipe.transform(item.illnessCost);
    });
    return data;
  }

  hospitalizationDataMapper(pre: any, data: any) {
    data.map((item: FamilyUserHospitalizationGet) => {
      item.hospitalizationStartDate = this.datePipe.transform(item.hospitalizationStartDate, 'YYYY-MM-dd');
    });
    return data;
  }

  educationInfoMapper(pre: any, data: any) {
    let tagsAll = '';
    if (data.educationTagIds && data.educationTagIds.length) {
      data.educationTagIds.map((tag: number) => {
        const title = pre.educationTag.find((item: PublicModel) => item.Id === tag)?.Title;
        tagsAll += this.translatePipe.transform(title) + ', ';
      });
    }
    data.educationTags = tagsAll;
    return data;
  }

  educationDataMapper(pre: any, data: any) {
    data.map((item: FamilyUserEducationFilter) => {
      item.studyStatus = this.translatePipe.transform(QualityStatuses[item.studyStatus]);
      item.startDate = this.datePipe.transform(item.startDate, 'YYYY-MM-dd');
      item.endDate = this.datePipe.transform(item.endDate, 'YYYY-MM-dd');
      item.studyingStatus = this.translatePipe.transform(EducationStatuses[item.studyingStatus]);
      let reasonsAll = '';
      if (item.dropoutReasonIds && item.dropoutReasonIds.length) {
        item.dropoutReasonIds.map((reason: number) => {
          const title = pre.dropoutReason.find((t: PublicModel) => t.Id === reason)?.Title;
          reasonsAll += this.translatePipe.transform(title) + ', ';
        });
      }
      item.dropoutReasons = reasonsAll;
    });
    return data;
  }

  jobDataMapper(pre: any, data: any) {
    data.map((item: FamilyUserJobHistoryGet) => {
      item.jobTitle = pre.job.find((t: RefuseReason) => t.id === item.jobId)?.title;
      item.startDate = this.datePipe.transform(item.startDate, 'YYYY-MM-dd');
      item.endDate = this.datePipe.transform(item.endDate, 'YYYY-MM-dd');
      item.monthlyIncome = this.pricePipe.transform(item.monthlyIncome);
    });
    return data;
  }

  skillDataMapper(pre: any, data: any) {
    data.map((item: FamilyUserSkillInfo) => {
      item.levelTitle = this.translatePipe.transform(QualityStatuses[item.level]);
    });
    return data;
  }

  otherInfoMapper(pre: any, data: any) {
    let interestsAll = '';
    if (data.interests && data.interests.length) {
      data.interests.map((interest: number) => {
        const title = pre.interest.find((item: PublicModel) => item.Id === interest)?.Title;
        interestsAll += this.translatePipe.transform(title) + ', ';
      });
    }
    data.interests_ = interestsAll;
    let wishesAll = '';
    if (data.wishIds && data.wishIds.length) {
      data.wishIds.map((wish: number) => {
        const title = pre.wish.find((item: PublicModel) => item.Id === wish)?.Title;
        wishesAll += this.translatePipe.transform(title) + ', ';
      });
    }
    data.wishes = wishesAll;
    let hobbies_All = '';
    if (data.hobbies && data.hobbies.length) {
      data.hobbies.map((hobby: number) => {
        const title = pre.hobby.find((item: PublicModel) => item.Id === hobby)?.Title;
        hobbies_All += this.translatePipe.transform(title) + ', ';
      });
    }
    data.hobbies_ = hobbies_All;
    let talents_All = '';
    if (data.talents && data.talents.length) {
      data.talents.map((talent: number) => {
        const title = pre.talent.find((item: PublicModel) => item.Id === talent)?.Title;
        talents_All += this.translatePipe.transform(title) + ', ';
      });
    }
    data.talents_ = talents_All;
    data.identificationDocumentDateOfIssue = this.datePipe.transform(data.identificationDocumentDateOfIssue, 'YYYY-MM-dd');
    data.identificationDocumentEndDate = this.datePipe.transform(data.identificationDocumentEndDate, 'YYYY-MM-dd');
    return data;
  }

  cashNeedDataMapper(pre: any, data: any) {
    data.map((item: FamilyCashNeedModel) => {
      item.costDonationTree = pre.donationTree.find((t: CampaignModel) => t.Id === item.costDonationTreeId)?.Title;
      item.amount = this.pricePipe.transform(item.amount);
      item.requestDate = this.datePipe.transform(item.requestDate, 'YYYY-MM-dd');
    });
    return data;
  }

  goodNeedDataMapper(pre: any, data: any) {
    data.map((item: FamilyGoodNeedModel) => {
      item.costDonationTree = pre.donationTree.find((t: CampaignModel) => t.Id === item.costDonationTreeId)?.Title;
      item.good = pre.good.find((t: PublicModel) => t.Id === item.productId)?.Title;
      item.requestDate = this.datePipe.transform(item.requestDate, 'YYYY-MM-dd');
    });
    return data;
  }

  serviceNeedDataMapper(pre: any, data: any) {
    data.map((item: FamilyServiceNeedModel) => {
      item.costDonationTree = pre.donationTree.find((t: CampaignModel) => t.Id === item.costDonationTreeId)?.Title;
      item.service = pre.service.find((t: PublicModel) => t.Id === item.serviceId)?.Title;
      item.requestDate = this.datePipe.transform(item.requestDate, 'YYYY-MM-dd');
    });
    return data;
  }

  attachDataMapper(pre: any, data: any) {
    let dataAlternative: any = [];
    const dataGroupByAttachmentGroup = this.groupByPipe.transform(data, 'attachmentGroup');
    dataGroupByAttachmentGroup.map((item, index) => {
      const temp = {};
      item.attachmentGroup = +item.key;
      item.attachmentGroupTitle = this.translatePipe.transform(AttachmentGroups[+item.key]);
      item.count = item.value.length;
      Object.keys(item).forEach((key) => {
        if (key !== 'key' && key !== 'value') {
          temp[key] = item[key];
        }
      });
      dataAlternative[index] = temp;
    });
    dataAlternative = dataAlternative.filter((item: any) => item.count);
    dataAlternative = {
      all: dataAlternative && dataAlternative.length ? dataAlternative : [{}],
      familyMember: dataAlternative.find((item: any) => item.attachmentGroup === AttachmentGroups.FAMILY_MEMBER) || null,
      health: dataAlternative.find((item: any) => item.attachmentGroup === AttachmentGroups.HEALTH) || null,
      education: dataAlternative.find((item: any) => item.attachmentGroup === AttachmentGroups.EDUCATION) || null
    };
    return dataAlternative;
  }

}
