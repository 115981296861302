import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
  {
    id: 10, icon: 'bx-circle', label: 'DONATIONS',
    subItems: [
      {
        id: 100, icon: 'bx-circle', label: 'CASH_DONATIONS', parentId: 10,
        subItems: [
          { id: 1000, icon: 'bx-circle', label: 'CONFIRM_REQUEST_DONATIONS', link: '/donation/cash/create-request', parentId: 100 },
          { id: 1001, icon: 'bx-circle', label: 'CASH_DONATION_REQUEST_LIST', link: '/donation/cash/request-list', parentId: 100 },
          { id: 1042, icon: 'bx-circle', label: 'CASH_DONATIONS', link: '/donation/cash/list', parentId: 100 }
        ]
      },
      {
        id: 101, icon: 'bx-circle', label: 'RETURN_CASH', parentId: 10,
        subItems: [
          { id: 1010, icon: 'bx-circle', label: 'SUBMIT_RETURN_TO_FUND', link: '/donation/cash/return-cash-receipt', parentId: 101 },
          { id: 1011, icon: 'bx-circle', label: 'RETURN_CASH_RECEIPT_OF_FINANCIAL_DONATION', link: '/donation/cash/return-cash-receipt-list', parentId: 101 }
        ]
      },
      {
        id: 102, icon: 'bx-circle', label: 'OBJECTIVES', parentId: 10,
        subItems: [
          { id: 1020, icon: 'bx-circle', label: 'SUBMIT_DONATION_GOODS_REQUEST', link: '/donation/goods/create-request', parentId: 102 },
          { id: 1021, icon: 'bx-circle', label: 'GOODS_DONATION_REQUEST_LIST', link: '/donation/goods/request-list', parentId: 102 }
        ]
      },
      {
        id: 103, icon: 'bx-circle', label: 'SERVANTS_GIFTS', parentId: 10,
        subItems: [
          { id: 1030, icon: 'bx-circle', label: 'SUBMIT_DONATION_SERVICE_REQUEST', link: '/donation/services/create-request', parentId: 103 },
          { id: 1031, icon: 'bx-circle', label: 'SERVICE_DONATION_REQUEST_LIST', link: '/donation/services/request-list', parentId: 103 },
          { id: 1032, icon: 'bx-circle', label: 'SERVICES_DONATION_LIST', link: '/donation/services/request-list-2', parentId: 103 }
        ]
      },
      {
        id: 104, icon: 'bx-circle', label: 'VOID_RECEIPTS', parentId: 10,
        subItems: [
          { id: 1040, icon: 'bx-circle', label: 'CREATE_VOID_RECEIPT', link: '/donation/void-receipt/create-receipt', parentId: 104 },
          { id: 1041, icon: 'bx-circle', label: 'VOID_RECEIPTS_LIST', link: '/donation/void-receipt/list', parentId: 104 }
        ]
      },
      {
        id: 105, icon: 'bx-circle', label: 'REPORT_DONATIONS', parentId: 10,
        subItems: [
          { id: 1050, icon: 'bx-circle', label: 'CASH_RECEIVES', link: '/donation/cash/receive-list', parentId: 105 },
          { id: 1051, icon: 'bx-circle', label: 'GOOD_RECEIVES', link: '/donation/goods/receive-list', parentId: 105 },
          { id: 1052, icon: 'bx-circle', label: 'SERVICE_RECEIVES', link: '/donation/services/receive-list', parentId: 105 },
          { id: 1053, icon: 'bx-circle', label: 'AGENT_RECEIVES', link: '/donation/cash/agent-receive-list', parentId: 105 }
        ]
      }
    ]
  },
  {
    id: 20, icon: 'bx-circle', label: 'AID',
    subItems: [
      {
        id: 200, icon: 'bx-circle', label: 'CASH_NEEDS', parentId: 20,
        subItems: [
          { id: 2000, icon: 'bx-circle', label: 'FAMILY_FINANCIAL_NEEDS_REQUEST_SUBMIT', link: '/aid/cash/family-financial-needs-request', parentId: 200 },
          { id: 2001, icon: 'bx-circle', label: 'FINANCIAL_NEEDS_REQUEST_LIST', link: '/aid/cash/family-needs-request-list', parentId: 200 },
          { id: 2002, icon: 'bx-circle', label: 'CASH_PAYMENT_REQUEST_LIST', link: '/aid/cash/cash-payment-request-list', parentId: 200 },
          { id: 2003, icon: 'bx-circle', label: 'CASH_PAYMENT_TO_AGENT_LIST', link: '/aid/cash/cash-payment-to-agent-list', parentId: 200 },
          { id: 2004, icon: 'bx-circle', label: 'CASH_DELIVERY_WORK_LIST', link: '/aid/cash/work-list', parentId: 200 },
          { id: 2005, icon: 'bx-circle', label: 'CASH_DELIVERY_LIST', link: '/aid/cash/delivery-report-list', parentId: 200 }
        ]
      },
      {
        id: 201, icon: 'bx-circle', label: 'GOODS_NEEDS', parentId: 20,
        subItems: [
          { id: 2010, icon: 'bx-circle', label: 'FAMILY_GOODS_NEEDS_REQUEST_SUBMIT', link: '/aid/goods/family-needs/goods-request-list', parentId: 201 },
          { id: 2011, icon: 'bx-circle', label: 'GOODS_NEEDS_REQUEST_LIST', link: '/aid/goods/family-needs-request-list', parentId: 201 },
          { id: 2012, icon: 'bx-circle', label: 'EXIT_GOOD_REQUEST_LIST', link: '/aid/goods/exit-good-request-list', parentId: 201 },
          { id: 2013, icon: 'bx-circle', label: 'EXIT_GOODS_FROM_WAREHOUSE_LIST', link: '/aid/goods/exit-good-warehouse-list', parentId: 201 },
          { id: 2014, icon: 'bx-circle', label: 'DELIVERY_WORK_LIST', link: '/aid/goods/delivery-work-list', parentId: 201 },
          { id: 2015, icon: 'bx-circle', label: 'GOOD_AID_DELIVERY_REPORT', link: '/aid/goods/delivery-list', parentId: 201 }
        ]
      },
      {
        id: 202, icon: 'bx-circle', label: 'PROGRAM', parentId: 20,
        subItems: [
          { id: 2020, icon: 'bx-circle', label: 'CREATE_PROGRAM_REQUEST', link: '/aid/program/create-request', parentId: 202 },
          { id: 2021, icon: 'bx-circle', label: 'PROGRAM_REQUEST_LIST', link: '/aid/program/request-list', parentId: 202 }
        ]
      }
    ]
  },
  {
    id: 30, icon: 'bx-circle', label: 'TRANSFER',
    subItems: [
      {
        id: 300, icon: 'bx-circle', label: 'CASH', parentId: 30,
        subItems: [
          { id: 3000, icon: 'bx-circle', label: 'CREATE_REQUEST', link: '/transfer/cash/create-request', parentId: 300 },
          { id: 3001, icon: 'bx-circle', label: 'REQUEST_LIST', link: '/transfer/cash/request-list', parentId: 300 },
          { id: 3002, icon: 'bx-circle', label: 'REQUEST_RECEIVED_LIST', link: '/transfer/cash/request-received-list', parentId: 300 },
          { id: 3003, icon: 'bx-circle', label: 'DELIVER_LIST', link: '/transfer/cash/deliver-list', parentId: 300 },
          { id: 3004, icon: 'bx-circle', label: 'DELIVER_LIST_IN_DESTINATION_BRANCH', link: '/transfer/cash/deliver-list-in-destination-branch', parentId: 300 }
        ]
      },
      {
        id: 301, icon: 'bx-circle', label: 'GOODS', parentId: 30,
        subItems: [
          { id: 3010, icon: 'bx-circle', label: 'CREATE_REQUEST', link: '/transfer/good/create-request', parentId: 301 },
          { id: 3011, icon: 'bx-circle', label: 'REQUEST_LIST', link: '/transfer/good/request-list', parentId: 301 },
          { id: 3012, icon: 'bx-circle', label: 'REQUEST_RECEIVED_LIST', link: '/transfer/good/request-received-list', parentId: 301 },
          { id: 3013, icon: 'bx-circle', label: 'DELIVER_LIST', link: '/transfer/good/deliver-list', parentId: 301 },
          { id: 3014, icon: 'bx-circle', label: 'DELIVER_LIST_IN_DESTINATION_BRANCH', link: '/transfer/good/deliver-list-in-destination-branch', parentId: 301 }
        ]
      }
    ]
  },
  {
    id: 10400, icon: 'bx-circle', label: 'SPONSORSHIP',
    subItems: [
      { id: 104000, icon: 'bx-circle',link: '/sponsorship/create-request', label: 'CREATE_REQUEST', parentId: 10400 },
      { id: 104001, icon: 'bx-circle',link: '/sponsorship/request-list', label: 'REQUEST_LIST', parentId: 10400 },
      { id: 104002, icon: 'bx-circle',link: '/sponsorship/create-receive-receipt', label: 'SUBMIT_SPONSOR_RECEIVE_RECEIPT', parentId: 10400 },
      { id: 104003, icon: 'bx-circle',link: '/sponsorship/receive-receipt-list', label: 'SPONSORS_RECEIVE_RECEIPT_LIST', parentId: 10400 },
      { id: 104004, icon: 'bx-circle',link: '/sponsorship/orphan-list', label: 'ORPHANS_WITH_SPONSOR_LIST', parentId: 10400 },
      { id: 104005, icon: 'bx-circle',link: '/sponsorship/edit-amount-request-list', label: 'SPONSORSHIP_EDIT_AMOUNT_REQUEST_LIST', parentId: 10400 },
      { id: 104006, icon: 'bx-circle',link: '/sponsorship/stop-request-list', label: 'STOP_SPONSORSHIP_REQUEST_LIST', parentId: 10400 },
      { id: 104007, icon: 'bx-circle',link: '/sponsorship/create-pay-salary-request', label: 'CREATE_SPONSORSHIP_SALARY_PAY_REQUEST', parentId: 10400 },
      { id: 104008, icon: 'bx-circle',link: '/sponsorship/pay-salary-request-list', label: 'SPONSORSHIP_PAY_SALARY_REQUEST_LIST', parentId: 10400 },
      { id: 104009, icon: 'bx-circle',link: '/sponsorship/salary-waiting-list', label: 'SALARY_WAITING_LIST', parentId: 10400 },
      { id: 104010, icon: 'bx-circle',link: '/sponsorship/paid-salary-to-families-report', label: 'PAID_SALARY_TO_FAMILIES_REPORT', parentId: 10400 },
      { id: 104011, icon: 'bx-circle',link: '/sponsorship/sponsor-list', label: 'SPONSOR_LIST', parentId: 10400 }
    ]
  },
  {
    id: 40, icon: 'bx-circle', label: 'MANAGEMENT',
    subItems: [
      { id: 400, icon: 'bx-circle', label: 'CASH_DONATIONS', parentId: 40,
        subItems: [
          { id: 4000, icon: 'bx-circle', label: 'DONATION_MANAGEMENT', link: '/management/donation/cash/list', parentId: 400 },
          { id: 4001, icon: 'bx-circle', label: 'CASH_RECEIPTS_DONATION_MANAGEMENT', link: '/management/donation/cash/cash-receipts', parentId: 400 },
          { id: 4002, icon: 'bx-circle', label: 'RETURN_CASH_RECEIPT_OF_FINANCIAL_DONATION_MANAGEMENT', link: '/management/donation/cash/return-cash-receipt-list', parentId: 400 }
        ]
      },
      { id: 401, icon: 'bx-circle', label: 'OBJECTIVES', parentId: 40,
        subItems: [
          { id: 4010, icon: 'bx-circle', label: 'REQUEST_MANAGEMENT', link: '/management/donation/goods/request-list', parentId: 401 }
        ]
      },
      { id: 402, icon: 'bx-circle', label: 'SERVANTS_GIFTS', parentId: 40,
        subItems: [
          { id: 4020, icon: 'bx-circle', label: 'SERVICE_DONATIONS_MANAGEMENT', link: '/management/donation/services/request-list-2', parentId: 402 }
        ]
      },
      { id: 403, icon: 'bx-circle', label: 'CASH_AID', parentId: 40,
        subItems: [
          { id: 4030, icon: 'bx-circle', label: 'REQUEST_MANAGEMENT', link: '/management/aid/cash/family-needs-request-list', parentId: 403 },
          { id: 4031, icon: 'bx-circle', label: 'CASH_DELIVERY_MANAGEMENT', link: '/management/aid/cash/delivery-report-list', parentId: 403 }
        ]
      },
      { id: 404, icon: 'bx-circle', label: 'GOOD_NEEDS', parentId: 40,
        subItems: [
          { id: 4040, icon: 'bx-circle', label: 'REQUEST_MANAGEMENT', link: '/management/aid/goods/family-needs-request-list', parentId: 404 },
          { id: 4041, icon: 'bx-circle', label: 'GOOD_AID_DELIVERY_REPORT', link: '/management/aid/goods/delivery-list', parentId: 404 }
        ]
      }
    ]
  },
  {
    id: 50, icon: 'bx-circle', label: 'ACCOUNTING',
    subItems: [
      {
        id: 500, icon: 'bx-circle', label: 'CASH_DONATIONS', parentId: 50,
        subItems: [
          { id: 5000, icon: 'bx-circle', label: 'SUBMIT_CASHIER_DELIVERY_RECEIPT', link: '/donation/cash/cash-receipts-save', parentId: 500 },
          { id: 5001, icon: 'bx-circle', label: 'CASH_RECEIPTS_DONATION_AUDIT', link: '/donation/cash/cash-receipts', parentId: 500 },
          { id: 5002, icon: 'bx-circle', label: 'RETURN_CASH_RECEIPT_OF_FINANCIAL_DONATION_AUDIT', link: '/accounting/donation/cash/return-cash-receipt-list', parentId: 500 }
        ]
      },
      {
        id: 501, icon: 'bx-circle', label: 'CASH_AID', parentId: 50,
        subItems: [
          { id: 5010, icon: 'bx-circle', label: 'REQUEST_AUDIT', link: '/accounting/aid/cash/family-needs-request-list', parentId: 501 },
          { id: 5011, icon: 'bx-circle', label: 'CASH_PAYMENT_REQUEST_AUDIT', link: '/accounting/aid/cash/cash-payment-request-list', parentId: 501 },
          { id: 5012, icon: 'bx-circle', label: 'CASH_PAYMENT_TO_AGENT_AUDIT', link: '/accounting/aid/cash/cash-payment-to-agent-list', parentId: 501 }
        ]
      },
      {
        id: 502, icon: 'bx-circle', label: 'GOOD_NEEDS', parentId: 50,
        subItems: [
          { id: 520, icon: 'bx-circle', label: 'EXIT_GOOD_REQUEST_AUDIT', link: '/accounting/aid/goods/exit-good-request-list', parentId: 502 }
        ]
      }
    ]
  },
  {
    id: 60, icon: 'bx-circle', label: 'FAMILY',
    subItems: [
      { id: 600, icon: 'bx-circle', label: 'FAMILY_REGISTER_REQUEST', link: '/family/family-register-request', parentId: 60 },
      { id: 601, icon: 'bx-circle', label: 'FAMILY_INQUIRY_LIST', link: '/family/family-inquiry-list', parentId: 60 },
      { id: 602, icon: 'bx-circle', label: 'FAMILY_LIST', link: '/family/family-list', parentId: 60 },
      { id: 603, icon: 'bx-circle', label: 'FAMILY_MEMBER_LIST', link: '/family/family-member-list', parentId: 60 },
      { id: 604, icon: 'bx-circle', label: 'FAMILY_ADVANCE_SEARCH', link: '/family/family-advance-search', parentId: 60 },
      { id: 605, icon: 'bx-circle', label: 'MEMBER_ADVANCE_SEARCH', link: '/family/member-advance-search', parentId: 60 },
      { id: 606, icon: 'bx-circle', label: 'PERSON_GROUP_LIST', link: '/family/person-group-list', parentId: 60 }
    ]
  },
  /* {
    id: 70, icon: 'bx-circle', label: 'BOX',
    subItems: [
      { id: 700, icon: 'bx-circle', label: 'BOX_LIST', link: '/box/box-list', parentId: 70 },
      { id: 701, icon: 'bx-circle', label: 'BOX_LOCATION', link: '/box/location', parentId: 70 },
      { id: 702, icon: 'bx-circle', label: 'COLLECTION_PERIODS_LIST', link: '/box/collection-periods-list', parentId: 70 },
      { id: 703, icon: 'bx-circle', label: 'COLLECTION_LOCATION_LIST', link: '/box/collection-location-list', parentId: 70 },
      { id: 704, icon: 'bx-circle', label: 'BOX_DELIVERY_RECEIPT_LIST', link: '/box/delivery-receipt-list', parentId: 70 }
    ]
  }, */
  {
    id: 80, icon: 'bx-circle', label: 'WAREHOUSING',
    subItems: [
      /* { id: 800, icon: 'bx-circle', label: 'NEW_TRANSFER', link: '/warehousing/transfer-good', parentId: 80 },
      { id: 801, icon: 'bx-circle', label: 'TRANSFER_LIST', link: '/warehousing/transfer-list', parentId: 80 },
      { id: 802, icon: 'bx-circle', label: 'TRANSFER_RECEIVE_LIST', link: '/warehousing/transfer-receive-list', parentId: 80 }, */
      { id: 803, icon: 'bx-circle', label: 'WAREHOUSING_INVENTORY', link: '/warehousing/inventory', parentId: 80 },
      { id: 804, icon: 'bx-circle', label: 'CASH_TREE_REPORT', link: '/warehousing/cash-tree-report', parentId: 80 },
      { id: 805, icon: 'bx-circle', label: 'PRODUCT_TREE_REPORT', link: '/warehousing/product-tree-report', parentId: 80 },
      // { id: 806, icon: 'bx-circle', label: 'SERVICE_TREE_REPORT', link: '/warehousing/service-tree-report', parentId: 80 }
    ]
  },
  {
    id: 90, icon: 'bx-circle', label: 'BUY',
    subItems: [
      { id: 900, icon: 'bx-circle', label: 'NEW_BUY_ORDER', link: '/buy/new-order', parentId: 90 },
      { id: 901, icon: 'bx-circle', label: 'BUY_ORDER_LIST', link: '/buy/order-list', parentId: 90 }
    ]
  },
  {
    id: 10000, icon: 'bx-circle', label: 'SETTINGS',
    subItems: []
  },
  {
    id: 10300, icon: 'bx-circle', label: 'HR',
    subItems: [
      {
        id: 103000, icon: 'bx-circle', label: 'SETTINGS', parentId: 10300,
        subItems: []
      },
      {
        id: 103001, icon: 'bx-circle', label: 'EMPLOYMENT', parentId: 10300,
        subItems: [
          { id: 1030010, icon: 'bx-circle', label: 'CREATE_REQUEST', link: '/hr/employment/create-request', parentId: 103001 },
          { id: 1030011, icon: 'bx-circle', label: 'REQUEST_LIST', link: '/hr/employment/request-list', parentId: 103001 }
        ]
      },
      {
        id: 103002, icon: 'bx-circle', label: 'PERSONNEL', parentId: 10300,
        subItems: [
          { id: 1030020, icon: 'bx-circle', label: 'PROFILE', link: '/hr/personnel/profile', parentId: 103002 },
          { id: 1030021, icon: 'bx-circle', label: 'PERSONNEL_LIST', link: '/hr/personnel/personnel-list', parentId: 103002 },
          { id: 1030022, icon: 'bx-circle', label: 'CREATE_CONTRACT', link: '/hr/personnel/create-contract', parentId: 103002 },
          { id: 1030023, icon: 'bx-circle', label: 'CONTRACT_LIST', link: '/hr/personnel/contract-list', parentId: 103002 },
          { id: 1030024, icon: 'bx-circle', label: 'CREATE_RULE', link: '/hr/personnel/create-rule', parentId: 103002 },
          { id: 1030025, icon: 'bx-circle', label: 'RULE_LIST', link: '/hr/personnel/rule-list', parentId: 103002 }
        ]
      },
      {
        id: 103003, icon: 'bx-circle', label: 'AGENTS', parentId: 10300,
        subItems: [
          { id: 1030030, icon: 'bx-circle', label: 'AGENT_LIST', link: '/hr/agent/list', parentId: 103003 },
          { id: 1030031, icon: 'bx-circle', label: 'CREATE_AGENT_SALARY', link: '/hr/agent/create-salary', parentId: 103003 },
          { id: 1030032, icon: 'bx-circle', label: 'AGENT_SALARY_LIST', link: '/hr/agent/salary-list', parentId: 103003 }
        ]
      },
      {
        id: 103004, icon: 'bx-circle', label: 'SALARY', parentId: 10300,
        subItems: [
          { id: 1030040, icon: 'bx-circle', label: 'CREATE_IMPREST', link: '/hr/salary/create-imprest', parentId: 103004 },
          { id: 1030041, icon: 'bx-circle', label: 'IMPREST_LIST', link: '/hr/salary/imprest-list', parentId: 103004 },
          { id: 1030042, icon: 'bx-circle', label: 'CREATE_LOAN', link: '/hr/salary/create-loan', parentId: 103004 },
          { id: 1030043, icon: 'bx-circle', label: 'LOAN_LIST', link: '/hr/salary/loan-list', parentId: 103004 },
          { id: 1030044, icon: 'bx-circle', label: 'CREATE_SALARY', link: '/hr/salary/create-salary', parentId: 103004 },
          { id: 1030045, icon: 'bx-circle', label: 'SALARY_LIST', link: '/hr/salary/salary-list', parentId: 103004 }
        ]
      },
      {
        id: 103005, icon: 'bx-circle', label: 'COOPERATION_LACK', parentId: 10300,
        subItems: [
          { id: 1030050, icon: 'bx-circle', label: 'REQUEST_LIST', link: '/hr/terminate-employment/request-list', parentId: 103005 }
        ]
      }
    ]
  },
  {
    id: 10100, icon: 'bx-circle', label: 'USERS_MANAGEMENT',
    subItems: [
      { id: 101000, icon: 'bx-circle', label: 'USER_LIST', link: '/user/user-list', parentId: 10100 },
      { id: 101001, icon: 'bx-circle', label: 'PERMISSION_GROUP_LIST', link: '/user/permission-group/list', parentId: 10100 }
    ]
  },
  {
    id: 10200, icon: 'bx-circle', label: 'LOG_MANAGEMENT',
    subItems: [
      { id: 102000, icon: 'bx-circle', label: 'LOG_LIST', link: '/log/log-list', parentId: 10200 }
    ]
  },
  {
    id: 10300, icon: 'bx-circle', label: 'ASSET_MANAGEMENT',
    subItems: [
      { id: 103000, icon: 'bx-circle', label: 'FIX_ASSET_LIST', link: '/asset/fix-asset-list', parentId: 10300 },
      { id: 103001, icon: 'bx-circle', label: 'DEPRECIATION_LIST', link: '/asset/depreciation-list', parentId: 10300 }
    ]
  }
];
