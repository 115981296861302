<ng-template #modal let-modal>
  <div class="modal-header">
    <h3> {{ (isUpdate ? 'EDIT' : 'NEW_SUBMIT') | translate }} </h3>
    <button (click)="modal.dismiss()" type="button" class="btn btn-close"> </button>
  </div>
  <div class="modal-body">
    <form #f="ngForm" [formGroup]="Form" (ngSubmit)="save()">
      <div class="row gy-2">
        <ng-container *ngFor="let data of data$ | async">
          <ng-container *ngIf="data.showInForm && !data.isHiddenParameter">
            <div *ngIf="data.inputType === inputTypes.Text" class="col-md-6 form-group">
              <app-simple-input [form]="f" [type]="data.size && (data.size === -1 || data.size > 250) ? 'textarea' : 'text'" [name]="data.columnName"
                                [label]="data.translate[langID].value" [required]="data.isRequired" [disabled]="isUpdate && !data.isEditable"> </app-simple-input>
            </div>
            <div *ngIf="data.inputType === inputTypes.Number" class="col-md-6 form-group">
              <app-simple-input [form]="f" inputType="number" [name]="data.columnName" [label]="data.translate[langID].value" [ltr]="true" [required]="data.isRequired"
                                [disabled]="isUpdate && !data.isEditable"> </app-simple-input>
            </div>
            <div *ngIf="data.inputType === inputTypes.Money" class="col-md-6 form-group">
              <price-input [form]="f" [name]="data.columnName" [label]="data.translate[langID].value" [required]="data.isRequired" [disabled]="isUpdate && !data.isEditable"> </price-input>
            </div>
            <div *ngIf="data.inputType === inputTypes.Select" class="col-md-6 form-group">
              <app-dropdown *ngIf="!data.needOnchangeEvent" [form]="f" [items]="data.inputItems" [name]="data.columnName" [label]="data.translate[langID].value"
                            bindLabel="title" [required]="data.isRequired" [disabled]="isUpdate && !data.isEditable"> </app-dropdown>
              <app-dropdown *ngIf="data.needOnchangeEvent" (valueChange)="onChangeItem($event, data)" (selectItem)="onSelectItem(data)" [form]="f" [items]="data.inputItems" [name]="data.columnName"
                            [label]="data.translate[langID].value" bindLabel="title" [required]="data.isRequired" [disabled]="isUpdate && !data.isEditable"> </app-dropdown>
            </div>
            <div *ngIf="data.inputType === inputTypes.Tree" class="col-md-6 form-group">
              <app-dropdown-tree [form]="f" (clear)="onClearNodeInForm(Form, data.columnName)" [nodes]="data.inputItems" [name]="data.columnName" displayField="title"
                                 [label]="data.translate[langID].value" [disabledParents]="parentTreeDisabled" [disabledParentsPro]="parentTreeDisabledPro" [hasSearch]="true"
                                 [hasCheckbox]="false" [required]="data.isRequired" [disabled]="(isUpdate && data.columnName === 'ParentId') || (isUpdate && !data.isEditable) || disables.includes(data.columnName)"
                                 [showPath]="data.columnName === 'GeoNameId'"> </app-dropdown-tree>
            </div>
            <div *ngIf="data.inputType === inputTypes.Date" class="col-md-6 form-group">
              <app-datepicker [form]="f" [name]="data.columnName" [label]="data.translate[langID].value" [required]="data.isRequired"
                              [disabled]="isUpdate && !data.isEditable"> </app-datepicker>
            </div>
            <div *ngIf="data.inputType === inputTypes.Check" class="col-md-6 form-group align-items-end d-flex mb-2">
              <app-checkbox [form]="f" [name]="data.columnName" [ids]="data.columnName" [label]="data.translate[langID].value"> </app-checkbox>
            </div>
            <div *ngIf="data.inputType === inputTypes.Phone" class="col-md-6 form-group">
              <app-simple-input [form]="f" type="phone" [name]="data.columnName" [label]="data.translate[langID].value" [required]="data.isRequired"
                                [disabled]="isUpdate && !data.isEditable"> </app-simple-input>
            </div>
          </ng-container>
        </ng-container>
      </div>
      <footer class="gap-3 d-flex my-3">
        <app-btn-custom type="submit" [theme]="{ type: 'fill', color: 'success', text: 'SUBMIT' }"> </app-btn-custom>
        <app-btn-custom *ngIf="isUpdate" (onClick)="modal.dismiss()" [theme]="{ type: 'solid', color: 'danger', text: 'CANCEL' }"> </app-btn-custom>
      </footer>
      <span *ngIf="textError" class="text-danger"> {{ textError | translate }} </span>
    </form>
  </div>
</ng-template>
