<ng-template #departments let-modal>
  <header class="modal-header">
    <h3> {{ 'DEPARTMENTS' | translate }} </h3>
    <button (click)="modal.dismiss()" type="button" class="btn btn-close"> </button>
  </header>
  <section class="modal-body">
    <div class="align-items-center justify-content-center gap-3 d-flex my-5">
      <app-btn-custom (onClick)="openModal(donor)" [theme]="{ type: 'fill', color: 'blue', text: 'ADD_NEW_PERSON_DONOR' }"> </app-btn-custom>
      <app-btn-custom (onClick)="openModal(institute)" [theme]="{ type: 'fill', color: 'blue', text: 'ADD_NEW_INSTITUTE_DONOR' }"> </app-btn-custom>
    </div>
  </section>
</ng-template>

<ng-template #institute let-modal>
  <header class="modal-header">
    <h3> {{ 'INSTITUTE' | translate }} </h3>
    <button (click)="modal.dismiss()" type="button" class="btn btn-close"> </button>
  </header>
  <section class="modal-body">
    <form #fInstitute="ngForm" [formGroup]="instituteForm" (ngSubmit)="instituteSubmit()" class="row gy-2">
      <div class="col-md-6 align-items-start gap-2 d-flex">
        <app-dropdown-tree (selectNode)="onChangeInstituteId($event)" (clear)="onChangeInstituteId($event)" [form]="fInstitute" [nodes]="institutes$ | async" name="instituteId"
                           [defaultTitle]="treeTitle" [selectedNode]="settingsDataSelected" [isEditting]="settingsDataSelected" idField="Id" label="INSTITUTE" displayField="Name"
                           [hasCheckbox]="false" [disabledParents]="true" class="flex-fill" [required]="true"> </app-dropdown-tree>
        <app-btn-custom (onClick)="OpenSettingsModal(settingIds.institutes)" [theme]="{ type: 'fill', color: 'blue', icon: !instituteForm.value.instituteId ? 'plus' : 'pencil' }"
                        class="btn-custom-donor" [loading]="settingsModalIsLoading"> </app-btn-custom>
      </div>
      <footer class="align-items-center justify-content-end gap-3 d-flex my-3">
        <app-btn-custom type="submit" [theme]="{ type: 'fill', color: 'blue', text: 'SUBMIT' }"> </app-btn-custom>
      </footer>
    </form>
  </section>
</ng-template>

<ng-template #donor let-modal>
  <header class="modal-header">
    <h3> {{ 'PERSON_DONOR_SPONSORSHIP' | translate }} </h3>
    <button (click)="modal.dismiss()" type="button" class="btn btn-close"> </button>
  </header>
  <section class="modal-body">
    <tab-bar (tabChange)="activeTab = $event" [tabs]="tabs" [activeId]="activeTab" [disabledTabs]="donorDisabledTabs" classList="justify-content-between gap-3 gap-lg-0 flex-column flex-lg-row d-flex">
      <app-payment-status *ngIf="donorInfoCreated.code" [status]="{ title: donorInfoCreated.code, color: 'default' }"> </app-payment-status>
    </tab-bar>
    <section [class.d-none]="activeTab !== tabs.DONOR_INFO" class="row my-4">
      <form #f1="ngForm" [formGroup]="donorForm" (ngSubmit)="donorInfoSubmit()" class="row gy-2">
        <div class="col-md-6 align-items-start gap-2 d-flex">
          <app-dropdown [form]="f1" [items]="userPrefixes$ | async" name="userPrefixId" bindValue="Id" label="PREFIX" bindLabel="Title" class="flex-fill"
                        [disabled]="donorInfoCreated.code && donationLength"> </app-dropdown>
          <app-btn-custom (onClick)="onLinkButton('userPrefixes')" [theme]="{ type: 'fill', color: 'blue', icon: 'link' }" class="btn-custom-donor"> </app-btn-custom>
        </div>
        <app-group-field [form]="f1" primaryCtrl="firstName" primaryLbl="FIRST_NAME" secondaryCtrl="lastName" secondaryLbl="LAST_NAME" label="NAME" class="col-md-6" [required]="true"
                         [disabled]="donorInfoCreated.code && donationLength"> </app-group-field>
        <app-dropdown [form]="f1" [items]="ancestryItems" name="ancestry" dataType="enum" label="ORIGIN" class="col-md-6" [required]="true" [disabledItem]="null"> </app-dropdown>
        <div class="col-md-6 align-items-start gap-2 d-flex">
          <app-dropdown-tree *ngIf="jobs$ | async as jobs" [form]="f1" [nodes]="jobs" name="jobId" idField="Id" label="JOB" displayField="Title" [hasCheckbox]="false"
                             class="flex-fill" [required]="true" [disabled]="donorInfoCreated.code && donationLength"> </app-dropdown-tree>
          <app-btn-custom (onClick)="onLinkButton('jobs')" [theme]="{ type: 'fill', color: 'blue', icon: 'link' }" class="btn-custom-donor"> </app-btn-custom>
        </div>
        <app-dropdown [form]="f1" [items]="genderItems" name="gender" dataType="enum" label="GENDER" class="col-md-6" [required]="true" [disabled]="donorInfoCreated.code && donationLength"> </app-dropdown>
        <app-simple-input [form]="f1" type="phone" name="phone" label="PHONE_NUMBER" class="col-md-6" [disabled]="donorInfoCreated.code && donationLength"> </app-simple-input>
        <app-simple-input [form]="f1" type="email" name="email" label="EMAIL" class="col-md-6"> </app-simple-input>
        <app-simple-input [form]="f1" name="description" label="DESCRIPTION" class="col-md-6"> </app-simple-input>
        <footer class="align-items-center justify-content-end gap-3 d-flex my-3">
          <app-btn-custom type="submit" [theme]="{ type: 'fill', color: 'blue', text: 'SUBMIT' }"> </app-btn-custom>
        </footer>
      </form>
    </section>
    <section [class.d-none]="activeTab !== tabs.CONTACT_INFO" class="row my-4">
      <ul class="address-list">
        <li [class.d-none]="accordion2.isExpanded('toggle-2') || accordion3.isExpanded('toggle-3') || accordion4.isExpanded('toggle-4')" class="accordion-custom address-list-item">
          <button (click)="accordion1.toggle('toggle-1')" type="button" class="accordion-button default">
            <div class="info-address">
              <h4> {{ 'HOME_LOCATION' | translate }} </h4>
              <p> {{ donorAddressForm.value.geoName || '---' }} / {{ donorAddressForm.value.address || '---' }} </p>
            </div>
            <div class="action-address">
              <i class="bx bx-{{ !donorAddressForm.value.id ? 'plus' : 'pencil' }}"> </i>
            </div>
          </button>
          <ngb-accordion #accordion1="ngbAccordion" class="acc">
            <ngb-panel id="toggle-1">
              <ng-template ngbPanelContent>
                <form #f2="ngForm" [formGroup]="donorAddressForm" (ngSubmit)="donorAddressInfoSubmit(accordion1)" class="row gy-2 p-0 m-0">
                  <app-map [form]="f2" [lat]="donorAddressForm.value.latitude" [lng]="donorAddressForm.value.longitude"> </app-map>
                  <div class="align-items-start gap-2 d-flex">
                    <app-dropdown-tree *ngIf="geoname$ | async as geoname" [form]="f2" [nodes]="geoname" name="geoNameId" label="AREA"
                                       [hasSearch]="true" [hasCheckbox]="false" [showPath]="true" class="flex-fill" [required]="true"> </app-dropdown-tree>
                    <app-btn-custom (onClick)="onLinkButton('geoName')" [theme]="{ type: 'fill', color: 'blue', icon: 'link' }" class="btn-custom-donor"> </app-btn-custom>
                  </div>
                  <app-simple-input [form]="f2" type="textarea" name="address" label="ADDRESS"> </app-simple-input>
                  <footer class="align-items-center justify-content-end gap-3 d-flex my-3">
                    <app-btn-custom (onClick)="accordion1.collapse('toggle-1')" [theme]="{ type: 'solid', color: 'blue', text: 'CANCEL' }"> </app-btn-custom>
                    <app-btn-custom type="submit" [theme]="{ type: 'fill', color: 'blue', text: 'SUBMIT' }"> </app-btn-custom>
                  </footer>
                </form>
              </ng-template>
            </ngb-panel>
          </ngb-accordion>
        </li>
        <li [class.d-none]="accordion1.isExpanded('toggle-1') || accordion3.isExpanded('toggle-3') || accordion4.isExpanded('toggle-4')" class="accordion-custom address-list-item">
          <button (click)="accordion2.toggle('toggle-2')" type="button" class="accordion-button default">
            <div class="info-address">
              <h4> {{ 'WORK_LOCATION' | translate }} </h4>
              <p> {{ donorWorkAddressForm.value.geoName || '---' }} / {{ donorWorkAddressForm.value.address || '---' }} </p>
            </div>
            <div class="action-address">
              <i class="bx bx-{{ !donorWorkAddressForm.value.id ? 'plus' : 'pencil' }}"> </i>
            </div>
          </button>
          <ngb-accordion #accordion2="ngbAccordion" class="acc">
            <ngb-panel id="toggle-2">
              <ng-template ngbPanelContent>
                <form #f3="ngForm" [formGroup]="donorWorkAddressForm" (ngSubmit)="donorWorkAddressInfoSubmit(accordion2)" class="row gy-2 p-0 m-0">
                  <app-map [form]="f3" [lat]="donorWorkAddressForm.value.latitude" [lng]="donorWorkAddressForm.value.longitude"> </app-map>
                  <div class="align-items-start gap-2 d-flex">
                    <app-dropdown-tree *ngIf="geoname$ | async as geoname" [form]="f3" [nodes]="geoname" name="geoNameId" label="AREA"
                                       [hasSearch]="true" [hasCheckbox]="false" [showPath]="true" class="flex-fill" [required]="true"> </app-dropdown-tree>
                    <app-btn-custom (onClick)="onLinkButton('geoName')" [theme]="{ type: 'fill', color: 'blue', icon: 'link' }" class="btn-custom-donor"> </app-btn-custom>
                  </div>
                  <app-simple-input [form]="f3" type="textarea" name="address" label="WORK_ADDRESS"> </app-simple-input>
                  <footer class="align-items-center justify-content-end gap-3 d-flex my-3">
                    <app-btn-custom (onClick)="accordion2.collapse('toggle-2')" [theme]="{ type: 'solid', color: 'blue', text: 'CANCEL' }"> </app-btn-custom>
                    <app-btn-custom type="submit" [theme]="{ type: 'fill', color: 'blue', text: 'SUBMIT' }"> </app-btn-custom>
                  </footer>
                </form>
              </ng-template>
            </ngb-panel>
          </ngb-accordion>
        </li>
        <li [class.d-none]="accordion1.isExpanded('toggle-1') || accordion2.isExpanded('toggle-2') || accordion4.isExpanded('toggle-4')" class="accordion-custom address-list-item">
          <button (click)="accordion3.toggle('toggle-3')" type="button" class="accordion-button default">
            <div class="info-address">
              <h4> {{ 'CALL_NUMBERS' | translate }} </h4>
            </div>
            <div class="action-address">
              <i class="bx bx-{{ !callNumbersData.length ? 'plus' : 'pencil' }}"> </i>
            </div>
          </button>
          <ngb-accordion #accordion3="ngbAccordion" class="acc">
            <ngb-panel id="toggle-3">
              <ng-template ngbPanelContent>
                <form #f4="ngForm" [formGroup]="donorCallNumberForm" (ngSubmit)="donorCallNumberInfoSubmit(f4)" class="row gy-2 p-0">
                  <div class="col-md-6 align-items-start gap-2 d-flex">
                    <app-dropdown [form]="f4" [items]="callNumberTypes$ | async" name="callNumberTypeId" bindValue="Id" label="CALL_NUMBER_TYPE" bindLabel="Title" class="flex-fill" [required]="true"> </app-dropdown>
                    <app-btn-custom (onClick)="onLinkButton('callNumberTypes')" [theme]="{ type: 'fill', color: 'blue', icon: 'link' }" class="btn-custom-donor"> </app-btn-custom>
                  </div>
                  <app-simple-input [form]="f4" type="phone" name="callNumber" label="CALL_NUMBER" class="col-md-6" [required]="true"> </app-simple-input>
                  <footer class="align-items-center justify-content-end gap-3 d-flex my-3">
                    <app-btn-custom (onClick)="accordion3.collapse('toggle-3')" [theme]="{ type: 'solid', color: 'blue', text: 'CANCEL' }"> </app-btn-custom>
                    <app-btn-custom type="submit" [theme]="{ type: 'fill', color: 'blue', text: !donorCallNumberForm.value.id ? 'SUBMIT' : 'EDIT' }"> </app-btn-custom>
                  </footer>
                </form>
                <div class="d-md-none">
                  <app-public-card *ngFor="let item of callNumbersData" (actionClick)="onCallNumberActionClick($event)" [mainFields]="['callNumberType', 'callNumber']"
                                   [colDefs]="callNumbersCols" [content]="item"> </app-public-card>
                </div>
                <portal-grid-table (actionClick)="onCallNumberActionClick($event)" [columns]="callNumbersCols" [data]="callNumbersData"
                                   [gridFit]="true" gridHeight="unset" class="d-none d-md-block"> </portal-grid-table>
              </ng-template>
            </ngb-panel>
          </ngb-accordion>
        </li>
        <li [class.d-none]="accordion1.isExpanded('toggle-1') || accordion2.isExpanded('toggle-2') || accordion3.isExpanded('toggle-3')" class="accordion-custom address-list-item">
          <button (click)="accordion4.toggle('toggle-4')" type="button" class="accordion-button default">
            <div class="info-address">
              <h4> {{ 'SOCIAL_NETWORKS' | translate }} </h4>
            </div>
            <div class="action-address">
              <i class="bx bx-{{ !socialNetworksData.length ? 'plus' : 'pencil' }}"> </i>
            </div>
          </button>
          <ngb-accordion #accordion4="ngbAccordion" class="acc">
            <ngb-panel id="toggle-4">
              <ng-template ngbPanelContent>
                <form #f5="ngForm" [formGroup]="donorSocialNetworkForm" (ngSubmit)="donorSocialNetworkInfoSubmit(f5)" class="row gy-2 p-0">
                  <div class="col-md-6 align-items-start gap-2 d-flex">
                    <app-dropdown [form]="f5" [items]="socialNetworkTypes$ | async" name="socialNetworkTypeId" bindValue="Id" label="SOCIAL_NETWORK_TYPE" bindLabel="Title"
                                  class="flex-fill" [required]="true"> </app-dropdown>
                    <app-btn-custom (onClick)="onLinkButton('socialNetworkTypes')" [theme]="{ type: 'fill', color: 'blue', icon: 'link' }" class="btn-custom-donor"> </app-btn-custom>
                  </div>
                  <app-simple-input [form]="f5" name="networkId" label="NETWORK" class="col-md-6" [required]="true"> </app-simple-input>
                  <footer class="align-items-center justify-content-end gap-3 d-flex my-3">
                    <app-btn-custom (onClick)="accordion4.collapse('toggle-4')" [theme]="{ type: 'solid', color: 'blue', text: 'CANCEL' }"> </app-btn-custom>
                    <app-btn-custom type="submit" [theme]="{ type: 'fill', color: 'blue', text: !donorSocialNetworkForm.value.id ? 'SUBMIT' : 'EDIT' }"> </app-btn-custom>
                  </footer>
                </form>
                <div class="d-md-none">
                  <app-public-card *ngFor="let item of socialNetworksData" (actionClick)="onSocialNetworkActionClick($event)" [mainFields]="['socialNetworkType', 'networkId']"
                                   [colDefs]="socialNetworksCols" [content]="item"> </app-public-card>
                </div>
                <portal-grid-table (actionClick)="onSocialNetworkActionClick($event)" [columns]="socialNetworksCols" [data]="socialNetworksData"
                                   [gridFit]="true" gridHeight="unset" class="d-none d-md-block"> </portal-grid-table>
              </ng-template>
            </ngb-panel>
          </ngb-accordion>
        </li>
      </ul>
    </section>
    <section [class.d-none]="activeTab !== tabs.REFERRALS" class="row my-4 gy-2">
      <div class="align-items-center justify-content-between d-flex">
        <h4 class="mb-0"> {{ 'REFERRALS_INFORMATION' | translate }} </h4>
        <app-btn-custom (click)="openModal(referrals, 'lg', 'referrals')" [theme]="{ type: 'fill', color: 'blue', icon: 'plus' }"> </app-btn-custom>
      </div>
      <div class="d-md-none">
        <app-public-card *ngFor="let item of referralsData" (actionClick)="onReferralActionClick($event)" [mainFields]="['fullName', 'job', 'phone']"
                         [colDefs]="referralsCols" [content]="item"> </app-public-card>
      </div>
      <portal-grid-table (actionClick)="onReferralActionClick($event)" [columns]="referralsCols" [data]="referralsData" gridHeight="unset" class="d-none d-md-block"> </portal-grid-table>
    </section>
  </section>
</ng-template>

<ng-template #referrals let-modal>
  <header class="modal-header">
    <h3> {{ 'REFERRAL' | translate }} </h3>
    <button (click)="modal.dismiss()" type="button" class="btn btn-close"> </button>
  </header>
  <section class="modal-body">
    <form #f6="ngForm" [formGroup]="referralsForm" (ngSubmit)="referralInfoSubmit()" class="row my-4 gy-2">
      <div class="col-md-6 align-items-start gap-2 d-flex">
        <app-dropdown [form]="f6" [items]="userPrefixes$ | async" name="userPrefixId" bindValue="Id" label="PREFIX" bindLabel="Title" class="flex-fill"> </app-dropdown>
        <app-btn-custom (onClick)="onLinkButton('userPrefixes')" [theme]="{ type: 'fill', color: 'blue', icon: 'link' }" class="btn-custom-donor"> </app-btn-custom>
      </div>
      <app-group-field [form]="f6" primaryCtrl="firstName" primaryLbl="FIRST_NAME" secondaryCtrl="lastName" secondaryLbl="LAST_NAME" label="NAME" class="col-md-6" [required]="true"> </app-group-field>
      <app-simple-input (valueChange)="isDuplicatePhone = false" [form]="f6" type="phone" name="phone" label="PHONE_NUMBER" class="col-md-6" [required]="true"> </app-simple-input>
      <div class="col-md-6 align-items-start gap-2 d-flex">
        <app-dropdown-tree *ngIf="jobs$ | async as jobs" [form]="f6" [nodes]="jobs" name="jobId" idField="Id" label="JOB" displayField="Title" [hasCheckbox]="false" [disabledParents]="true"
                           class="flex-fill"> </app-dropdown-tree>
        <app-btn-custom (onClick)="onLinkButton('jobs')" [theme]="{ type: 'fill', color: 'blue', icon: 'link' }" class="btn-custom-donor"> </app-btn-custom>
      </div>
      <app-simple-input [form]="f6" name="email" label="EMAIL" class="col-md-6"> </app-simple-input>
      <footer class="align-items-center justify-content-end gap-3 d-flex my-3">
        <app-btn-custom type="submit" [theme]="{ type: 'fill', color: 'blue', text: 'SUBMIT' }"> </app-btn-custom>
      </footer>
      <span *ngIf="isDuplicatePhone" class="text-danger mt-0"> {{ 'THE_PHONE_NUMBER_ENTERED_IS_DUPLICATE' | translate }} </span>
    </form>
  </section>
</ng-template>

<app-settings-modal *ngIf="isSettingsModal" (isLoading)="settingsModalIsLoading = $event" (dismissed)="settingsModalDismissed($event)" [param]="settingsParam" [dataSelected]="settingsDataSelected"> </app-settings-modal>

<ng-template #confirmModal let-modal>
  <app-confirm-modal (confirm)="modal.dismiss('confirm')" [modalRef]="modal" title="THIS_USER_IS_EXIST" text="PLEASE_SUBMIT_OTHER_PHONE_NUMBER"
                     secondColor="c-3" secondText="OK" [isInfo]="true"> </app-confirm-modal>
</ng-template>

<ng-template #deleteModal let-modal>
  <app-confirm-modal (confirm)="modal.dismiss('confirm')" [modalRef]="modal" color="danger" secondColor="c-3"> </app-confirm-modal>
</ng-template>
