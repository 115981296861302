<ng-template #userModal let-modal>
  <header class="modal-header">
    <h3> {{ 'USER' | translate }} </h3>
    <button (click)="modal.dismiss()" type="button" class="btn btn-close"> </button>
  </header>
  <section class="modal-body">
    <tab-bar (tabChange)="activeTab = $event" [tabs]="tabs" [activeId]="activeTab" [disabledTabs]="disableTabs" classList="justify-content-between d-flex mb-3"> </tab-bar>
    <section [class.d-none]="activeTab !== tabs.PERSON_INFO">
      <form #f1="ngForm" [formGroup]="personInfoForm" (ngSubmit)="personInfoSubmit()" class="row gy-2">
        <div class="col-md-6 align-items-start gap-2 d-flex">
          <app-dropdown [form]="f1" [items]="userPrefixes$ | async" name="userPrefixId" bindValue="Id" label="PREFIX" bindLabel="Title" class="flex-fill" [disabled]="true"> </app-dropdown>
          <!-- <app-btn-custom (onClick)="onLinkButton('userPrefixes')" [theme]="{ type: 'fill', color: 'blue', icon: 'link' }" class="btn-custom-donor"> </app-btn-custom> -->
        </div>
        <app-group-field [form]="f1" primaryCtrl="firstName" primaryLbl="FIRST_NAME" secondaryCtrl="lastName" secondaryLbl="LAST_NAME" label="NAME" class="col-md-6" [required]="true" [disabled]="true"> </app-group-field>
        <app-dropdown [form]="f1" [items]="ancestryItems" name="ancestry" dataType="enum" label="ORIGIN" class="col-md-6" [required]="true" [disabled]="true" [disabledItem]="null"> </app-dropdown>
        <!-- <div class="col-md-6 align-items-start gap-2 d-flex">
          <app-dropdown-tree [form]="f1" [nodes]="jobs$ | async" name="jobId" idField="Id" label="JOB" displayField="Title" [hasCheckbox]="false" [disabledParents]="true"
                             class="flex-fill" [required]="true"> </app-dropdown-tree>
          <app-btn-custom (onClick)="onLinkButton('jobs')" [theme]="{ type: 'fill', color: 'blue', icon: 'link' }" class="btn-custom-donor"> </app-btn-custom>
        </div> -->
        <app-dropdown [form]="f1" [items]="genderItems" name="gender" dataType="enum" label="GENDER" class="col-md-6" [disabled]="true"> </app-dropdown>
        <app-simple-input [form]="f1" type="phone" name="phone" label="PHONE_NUMBER" class="col-md-6" [required]="true" [disabled]="true"> </app-simple-input>
        <app-simple-input [form]="f1" type="email" name="email" label="EMAIL" class="col-md-6" [disabled]="true"> </app-simple-input>
        <app-checkbox [form]="f1" name="isActive" ids="IS_ACTIVE" label="ACTIVE" class="col-md-6 align-items-end d-flex my-3" [disabled]="true"> </app-checkbox>
        <!-- <footer class="align-items-center justify-content-end d-flex my-3">
          <app-btn-custom type="submit" [theme]="{ type: 'fill', color: 'blue', text: disableTabs.length && !employeeCreateStatus ? 'SUBMIT' : 'EDIT' }"> </app-btn-custom>
        </footer> -->
      </form>
    </section>
    <!-- <section [class.d-none]="activeTab !== tabs.ORGANIZATIONAL_INFO">
      <form #f2="ngForm" [formGroup]="organizationalInfoForm" (ngSubmit)="organizationalInfoSubmit()" class="row gy-2">
        <app-dropdown [form]="f2" [items]="branches$ | async" name="branchId" label="BRANCH" bindLabel="title" class="col-md-6" [required]="true" [disabled]="isBranch || branchIsDisabled"> </app-dropdown>
        <app-simple-input [value]="userSelected?.department" label="DEPARTMENT" class="col-md-6" [disabled]="true"> </app-simple-input>
        <div *ngIf="!isBranch" class="align-items-center justify-content-end d-flex my-3">
          <app-btn-custom type="submit" [theme]="{ type: 'fill', color: 'blue', text: disableTabs.length && !branchAddStatus ? 'SUBMIT' : 'EDIT' }"> </app-btn-custom>
        </div>
        <ng-container *ngIf="isBranch">
          <ul class="list-unstyled gap-2 flex-column d-flex m-3">
            <li *ngFor="let item of roles">
              <input (change)="selectUserRole($event)" type="checkbox" [value]="item.id" [id]="'role-' + item.name.toLowerCase()" class="form-check-input" [attr.checked]="item.active ? '' : null" />
              <label [for]="'role-' + item.name.toLowerCase()" class="mb-0 ms-2"> {{ item.name | translate }} </label>
            </li>
          </ul>
        </ng-container>
      </form>
    </section> -->
    <section [class.d-none]="activeTab !== tabs.USER_INFO">
      <form #f3="ngForm" [formGroup]="userInfoForm" (ngSubmit)="userInfoSubmit()" class="row flex-column gy-2">
        <app-simple-input [form]="f3" type="phone" name="phone" label="USERNAME" class="col-md-6" [required]="disableTabs.length && !passwordSetStatus" [disabled]="true"> </app-simple-input>
        <app-simple-input [form]="f3" inputType="password" name="newPassword" label="PASSWORD" class="col-md-6" [required]="disableTabs.length && !passwordSetStatus"> </app-simple-input>
        <app-simple-input [form]="f3" inputType="password" name="confirmPassword" label="CONFIRM_PASSWORD" class="col-md-6" [required]="disableTabs.length && !passwordSetStatus"> </app-simple-input>
        <footer class="align-items-center justify-content-end d-flex my-3">
          <app-btn-custom type="submit" [theme]="{ type: 'fill', color: 'blue', text: disableTabs.length && !passwordSetStatus ? 'SUBMIT' : 'SUBMIT_OR_SKIP' }"> </app-btn-custom>
        </footer>
      </form>
    </section>
    <section [class.d-none]="activeTab !== tabs.SINGLE_PERMISSIONS">
      <ng-container *ngIf="userSelected.employeeCode || userSelected.branch">
        <ul class="list-unstyled gap-2 flex-column d-flex my-3">
          <li *ngFor="let item of roles">
            <input (change)="selectUserRole($event)" type="checkbox" [value]="item.id" [id]="'role-' + item.name.toLowerCase()" class="form-check-input" [attr.checked]="item.active ? '' : null" />
            <label [for]="'role-' + item.name.toLowerCase()" class="mb-0 ms-2"> {{ item.name | translate }} </label>
          </li>
          <li>
            <input (change)="selectDataFiltered($event)" type="checkbox" [(ngModel)]="isOnlyShowPersonalInfo" id="data-filtered" class="form-check-input" />
            <label for="data-filtered" class="mb-0 ms-2"> {{ 'DATA_FILTERED' | translate }} </label>
          </li>
        </ul>
      </ng-container>
      <search-field (onSearch)="onSearch($event)" placeholder="SEARCH" class="d-block my-3"> </search-field>
      <app-drag-scroll class="my-3">
        <app-sort-box (sortFilter)="onPermissionSort('active', $event)" [sortItems]="activeStatuses" sort="STATUS"> </app-sort-box>
      </app-drag-scroll>
      <app-drag-scroll class="my-3">
        <app-sort-box (sortFilter)="onPermissionSort('appId', $event)" [sortEnum]="appStatuses" sort="DEPARTMENT"> </app-sort-box>
      </app-drag-scroll>
      <div class="align-items-start gap-3 flex-row-reverse d-flex">
        <!-- <app-btn-custom (onClick)="selectPermissionAll()" [theme]="{ type: 'fill', color: 'blue', text: 'SELECT_ALL' }"> </app-btn-custom> -->
        <ul class="list-unstyled overflow-auto gap-2 flex-fill flex-column d-flex" style="height: 300px">
          <li *ngFor="let item of permissionsFiltered">
            <input (change)="selectPermission($event)" type="checkbox" [value]="item.id" [(ngModel)]="item.active" [id]="'permission-' + item.title.toLowerCase()"
                   class="form-check-input" [attr.checked]="item.active ? '' : null" />
            <label [for]="'permission-' + item.title.toLowerCase()" class="mb-0 ms-2"> {{ item.title | translate }} </label>
          </li>
        </ul>
      </div>
      <!-- <footer class="align-items-center justify-content-end d-flex my-3">
        <app-btn-custom (onClick)="modal.dismiss()" [theme]="{ type: 'fill', color: 'blue', text: 'CLOSE' }" [disabled]="!permissionIsSelected"> </app-btn-custom>
      </footer> -->
    </section>
    <section [class.d-none]="activeTab !== tabs.GROUP_PERMISSIONS">
      <div class="overflow-auto gap-5 d-flex my-3" style="height: 300px">
        <ul class="list-unstyled gap-2 flex-fill flex-column d-flex">
          <li *ngFor="let item of permissionGroups">
            <input (change)="selectPermissionGroup($event)" type="radio" [value]="item.id" name="permission-g" [id]="'permission-g-' + item.title.toLowerCase()"
                   class="form-check-input" [attr.checked]="item.active ? '' : null" />
            <label [for]="'permission-g-' + item.title.toLowerCase()" class="mb-0 ms-2"> {{ item.title | translate }} </label>
          </li>
        </ul>
        <ul class="list-unstyled gap-2 flex-fill flex-column d-flex">
          <li *ngFor="let item of permissionsByGroupId">
            <input (change)="selectPermission($event)" type="checkbox" [value]="item.permissionId" [(ngModel)]="item.active" [id]="'permission-by-g-' + item.title.toLowerCase()"
                   class="form-check-input" [attr.checked]="item.active ? '' : null" />
            <label [for]="'permission-by-g-' + item.title.toLowerCase()" class="mb-0 ms-2"> {{ item.title | translate }} </label>
          </li>
        </ul>
      </div>
    </section>
  </section>
</ng-template>

<ng-template #confirmModal let-modal>
  <app-confirm-modal (confirm)="modal.dismiss('confirm')" [modalRef]="modal" title="THIS_USER_IS_EXIST" text="WANT_TO_EDIT" secondColor="c-3"> </app-confirm-modal>
</ng-template>
