<main *ngIf="!loading else loadingTemplate" class="gap-3 flex-column d-flex">
  <table>
    <thead>
      <tr>
        <th>
          <header id="main-header" class="gap-3 d-flex">
            <div class="gap-3 flex-column flex-fill d-flex">
              <h2> موسسه الیتیم الخیریه <span> ({{ translatePipe.transform('PROFILE_MEMBER') }}) </span> </h2>
              <div class="gap-3 d-flex">
                <img *ngIf="selected.imageUrl" [src]="env + '/blob/' + selected.imageUrl" width="90" height="80" alt="" class="rounded-circle" />
                <div class="row flex-fill gy-2">
                  <div class="col-md-6"> <label> {{ translatePipe.transform('CODE') }} : </label> <span> {{ selected.code }} </span> </div>
                  <div class="col-md-6"> <label> {{ translatePipe.transform('FAMILY_CODE') }} : </label> <span> {{ member.info.familyCode }} </span> </div>
                  <div class="col-md-6"> <label> {{ translatePipe.transform('CREATION_DATE') }} : </label> <pre> {{ member.familyInfo.firstFinalConfirm }} </pre> </div>
                  <div class="col-md-6"> <label> {{ translatePipe.transform('LAST_UPDATE_DATE') }} : </label> <pre> {{ member.familyInfo.lastFinalConfirm }} </pre> </div>
                  <div class="col-md-6"> <label> {{ translatePipe.transform('BRANCH') }} : </label> <span> {{ member.familyInfo.branch }} </span> </div>
                  <div class="col-md-6"> <label> {{ translatePipe.transform('PRINT_DATE') }} : </label> <pre> {{ printDate }} </pre> </div>
                </div>
              </div>
            </div>
            <img src="assets/images/logo-alyatim-2.svg" height="120" alt="" />
          </header>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>
          <section class="gap-3 flex-column d-flex">
            <table>
              <thead class="with-title"> <tr> <th colspan="100%"> <h3> {{ translatePipe.transform('MEMBER_INFO') }} </h3> </th> </tr> </thead>
              <tbody>
                <tr>
                  <td>
                    <div class="row gy-3">
                      <!-- <div class="col-md-6"> <label> {{ translatePipe.transform('PREFIX') }} : </label> <span> {{ member.info.userPrefix }} </span> </div> -->
                      <div class="col-md-6"> <label> {{ translatePipe.transform('NAME') }} : </label> <span> {{ member.info.fullName }} </span> </div>
                      <div class="col-md-6"> <label> {{ translatePipe.transform('BIRTH_DATE') }} : </label> <pre> {{ member.info.birthDate }} </pre> </div>
                      <div class="col-md-6"> <label> {{ translatePipe.transform('ANCESTRY') }} : </label> <span> {{ member.info.ancestry }} </span> </div>
                      <div class="col-md-6"> <label> {{ translatePipe.transform('GENDER') }} : </label> <span> {{ member.info.gender }} </span> </div>
                      <div class="col-md-6"> <label> {{ translatePipe.transform('MARITAL_STATUS') }} : </label> <span> {{ member.info.maritalStatus }} </span> </div>
                      <div class="col-md-6"> <label> {{ translatePipe.transform('JOB') }} : </label> <span> {{ member.info.job }} </span> </div>
                      <div class="col-md-6"> <label> {{ translatePipe.transform('PHONE') }} : </label> <span> {{ member.info.phone }} </span> </div>
                      <div class="col-md-6"> <label> {{ translatePipe.transform('EMAIL') }} : </label> <span> {{ member.info.email }} </span> </div>
                      <div class="col-md-6">
                        <label> {{ translatePipe.transform('IS_RELIGIOUS_COMMITMENT') }} : </label>
                        <i [class]="'bx fw-bolder font-size-20 position-relative mx-1 ' + (selected.isReligiousCommitment ? 'bx-check' : 'bx-x')" style="top: 3px"> </i>
                      </div>
                      <div class="col-md-6"> <label> {{ translatePipe.transform('ID_DOCUMENT_TYPE') }} : </label> <span> {{ member.info['identificationDocument'] }} </span> </div>
                      <div class="col-md-6"> <label> {{ translatePipe.transform('ISSUE_DATE') }} : </label> <pre> {{ member.info['identificationDocumentDateOfIssue'] }} </pre> </div>
                      <div class="col-md-6"> <label> {{ translatePipe.transform('EXPIRY_DATE') }} : </label> <pre> {{ member.info['identificationDocumentEndDate'] }} </pre> </div>
                      <div class="col-md-6"> <label> {{ translatePipe.transform('ID_DOCUMENT_NUMBER') }} : </label> <span> {{ member.info['identificationDocumentNumber'] }} </span> </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <ng-container *ngIf="member.attachData && member.attachData.familyMember">
              <table>
                <thead class="with-title"> <tr> <th colspan="100%"> <h4> {{ translatePipe.transform('ATTACH') }} </h4> </th> </tr> </thead>
                <tbody>
                <tr>
                  <td>
                    <div class="row gy-3">
                      <div class="col-md-6"> <label> {{ translatePipe.transform('COUNT_UPLOADED') }} : </label> <span> {{ member.attachData.familyMember.count }} </span> </div>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
            </ng-container>
          </section>
          <section class="gap-3 flex-column d-flex">
            <table>
              <thead class="with-title"> <tr> <th colspan="100%"> <h3> {{ translatePipe.transform('CONTACT_INFO') }} </h3> </th> </tr> </thead>
              <tbody>
                <tr>
                  <td>
                    <section class="gap-3 flex-column d-flex">
                      <table>
                        <thead class="with-title"> <tr> <th colspan="100%"> <h4> {{ translatePipe.transform('HOME_LOCATION_FAMILY_INFO') }} </h4> </th> </tr> </thead>
                        <tbody>
                          <tr>
                            <td>
                              <div class="row gy-3">
                                <div class="col-md-6"> <label> {{ translatePipe.transform('CITY') }} : </label> <span> {{ member.familyInfo.geoName }} </span> </div>
                                <div class="col-md-6"> <label> {{ translatePipe.transform('ADDRESS') }} : </label> <span> {{ member.familyInfo.address }} </span> </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <ng-container *ngIf="member.addressInfo && member.addressInfo.home">
                        <table>
                          <thead class="with-title"> <tr> <th colspan="100%"> <h4> {{ translatePipe.transform('HOME_LOCATION') }} </h4> </th> </tr> </thead>
                          <tbody>
                            <tr>
                              <td>
                                <div class="row gy-3">
                                  <div class="col-md-6"> <label> {{ translatePipe.transform('AREA') }} : </label> <span> {{ member.addressInfo.home.geoName }} </span> </div>
                                  <div class="col-md-6"> <label> {{ translatePipe.transform('ADDRESS') }} : </label> <span> {{ member.addressInfo.home.address }} </span> </div>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </ng-container>
                      <ng-container *ngIf="member.addressInfo && member.addressInfo.work">
                        <table>
                          <thead class="with-title"> <tr> <th colspan="100%"> <h4> {{ translatePipe.transform('WORK_LOCATION') }} </h4> </th> </tr> </thead>
                          <tbody>
                            <tr>
                              <td>
                                <div class="row gy-3">
                                  <div class="col-md-6"> <label> {{ translatePipe.transform('AREA') }} : </label> <span> {{ member.addressInfo.work.geoName }} </span> </div>
                                  <div class="col-md-6"> <label> {{ translatePipe.transform('ADDRESS') }} : </label> <span> {{ member.addressInfo.work.address }} </span> </div>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </ng-container>
                      <ng-container *ngIf="member.callNumbersData && member.callNumbersData.length">
                        <table class="table-data table-bordered table-striped w-100">
                          <thead class="with-title">
                            <tr> <th colspan="100%"> <h4> {{ translatePipe.transform('CALL_NUMBERS') }} </h4> </th> </tr>
                            <tr>
                              <th style="width: 60px"> # </th>
                              <th> {{ translatePipe.transform('CALL_NUMBER_TYPE') }} </th>
                              <th> {{ translatePipe.transform('CALL_NUMBER') }} </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let item of member.callNumbersData; let index = index">
                              <td> {{ index + 1 }} </td>
                              <td> {{ item.callNumberType }} </td>
                              <td> {{ item.callNumber }} </td>
                            </tr>
                          </tbody>
                        </table>
                      </ng-container>
                      <ng-container *ngIf="member.socialNetworksData && member.socialNetworksData.length">
                        <table class="table-data table-bordered table-striped w-100">
                          <thead class="with-title">
                            <tr> <th colspan="100%"> <h4> {{ translatePipe.transform('SOCIAL_NETWORKS') }} </h4> </th> </tr>
                            <tr>
                              <th style="width: 60px"> # </th>
                              <th> {{ translatePipe.transform('SOCIAL_NETWORK_TYPE') }} </th>
                              <th> {{ translatePipe.transform('NETWORK') }} </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let item of member.socialNetworksData; let index = index">
                              <td> {{ index + 1 }} </td>
                              <td> {{ item.socialNetworkType }} </td>
                              <td> {{ item.networkId }} </td>
                            </tr>
                          </tbody>
                        </table>
                      </ng-container>
                    </section>
                  </td>
                </tr>
              </tbody>
            </table>
          </section>
          <section *ngIf="hasData.HEALTH_INFO" class="gap-3 flex-column d-flex">
            <table>
              <thead class="with-title"> <tr> <th colspan="100%"> <h3> {{ translatePipe.transform('HEALTH_INFO') }} </h3> </th> </tr> </thead>
              <tbody>
                <tr>
                  <td>
                    <section class="gap-3 flex-column d-flex">
                      <ng-container *ngIf="member.healthData && member.healthData.length">
                        <table class="table-data table-bordered table-striped w-100">
                          <thead class="with-title">
                          <tr> <th colspan="100%"> <h4> {{ translatePipe.transform('HEALTH_RECORDS') }} </h4> </th> </tr>
                            <tr>
                              <th style="width: 60px"> # </th>
                              <th> {{ translatePipe.transform('HEALTH_STATUS') }} </th>
                              <th> {{ translatePipe.transform('HEIGHT') }} </th>
                              <th> {{ translatePipe.transform('WHEIGHT') }} </th>
                              <th> {{ translatePipe.transform('SHOE_SIZE') }} </th>
                              <th> {{ translatePipe.transform('WAIST') }} </th>
                              <th> {{ translatePipe.transform('HEALTH_TAGS') }} </th>
                              <th> {{ translatePipe.transform('VISUAL_LEVEL') }} </th>
                              <th> {{ translatePipe.transform('HEARING_LEVEL') }} </th>
                              <th> {{ translatePipe.transform('PRONUNCIATION_LEVEL') }} </th>
                              <th> {{ translatePipe.transform('CREATE_DATE') }} </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let item of member.healthData; let index = index">
                              <td> {{ index + 1 }} </td>
                              <td> {{ item.healthStatus }} </td>
                              <td> {{ item.height }} </td>
                              <td> {{ item.weight }} </td>
                              <td> {{ item.shoeSize }} </td>
                              <td> {{ item.waistSize }} </td>
                              <td> {{ item.tagsAll }} </td>
                              <td> {{ item.visualLevel }} </td>
                              <td> {{ item.hearingLevel }} </td>
                              <td> {{ item.pronunciationLevel }} </td>
                              <td> {{ item.createDate }} </td>
                            </tr>
                          </tbody>
                        </table>
                      </ng-container>
                      <ng-container *ngIf="member.deathInfo">
                        <table>
                          <thead class="with-title"> <tr> <th colspan="100%"> <h4> {{ translatePipe.transform('DEATH') }} </h4> </th> </tr> </thead>
                          <tbody>
                            <tr>
                              <td>
                                <div class="row gy-3">
                                  <div class="col-md-6"> <label> {{ translatePipe.transform('DEATH_CERTIFICATE_NUMBER') }} : </label> <span> {{ member.deathInfo.certificateNo }} </span> </div>
                                  <div class="col-md-6"> <label> {{ translatePipe.transform('CERTIFICATE_DATE') }} : </label> <span> {{ member.deathInfo.dateOfDeathCertificate }} </span> </div>
                                  <div class="col-md-6"> <label> {{ translatePipe.transform('DEATH_REASON') }} : </label> <span> {{ member.deathInfo.deathReason }} </span> </div>
                                  <div class="col-md-6"> <label> {{ translatePipe.transform('DATE_OF_DEATH') }} : </label> <span> {{ member.deathInfo.date }} </span> </div>
                                  <div class="col-md-6"> <label> {{ translatePipe.transform('DEATH_TYPE') }} : </label> <span> {{ member.deathInfo.deathType }} </span> </div>
                                  <div class="col-md-6"> <label> {{ translatePipe.transform('DEATH_DESCRIPTION') }} : </label> <span> {{ member.deathInfo.description }} </span> </div>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </ng-container>
                      <ng-container *ngIf="member.illnessData && member.illnessData.length">
                        <table class="table-data table-bordered table-striped w-100">
                          <thead class="with-title">
                            <tr> <th colspan="100%"> <h4> {{ translatePipe.transform('ILLNESSES') }} </h4> </th> </tr>
                            <tr>
                              <th style="width: 60px"> # </th>
                              <th> {{ translatePipe.transform('ILLNESS_TITLE') }} </th>
                              <th> {{ translatePipe.transform('ILLNESS_ENG_TITLE') }} </th>
                              <th> {{ translatePipe.transform('ILLNESS_STATUS') }} </th>
                              <th> {{ translatePipe.transform('COST') + ' (' + translatePipe.transform('IQD') + ')' }} </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let item of member.illnessData; let index = index">
                              <td> {{ index + 1 }} </td>
                              <td> {{ item.illness }} </td>
                              <td> {{ item.englishIllness }} </td>
                              <td> {{ item.isCured }} </td>
                              <td> {{ item.illnessCost }} </td>
                            </tr>
                          </tbody>
                        </table>
                      </ng-container>
                      <ng-container *ngIf="member.hospitalizationData && member.hospitalizationData.length">
                        <table class="table-data table-bordered table-striped w-100">
                          <thead class="with-title">
                            <tr> <th colspan="100%"> <h4> {{ translatePipe.transform('HOSPITALIZATION_AND_SURGERY_RECORDS') }} </h4> </th> </tr>
                            <tr>
                              <th style="width: 60px"> # </th>
                              <th> {{ translatePipe.transform('HOSPITAL_NAME') }} </th>
                              <th> {{ translatePipe.transform('DOCTOR_NAME') }} </th>
                              <th> {{ translatePipe.transform('HOSPITALIZATION_DATE') }} </th>
                              <th> {{ translatePipe.transform('HOSPITALIZATION_DAY_COUNT') }} </th>
                              <th> {{ translatePipe.transform('HOSPITALIZATION_REASON') }} </th>
                              <th> {{ translatePipe.transform('SURGERIED') }} </th>
                              <th> {{ translatePipe.transform('SURGERY_REASON') }} </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let item of member.hospitalizationData; let index = index">
                              <td> {{ index + 1 }} </td>
                              <td> {{ item.hospital }} </td>
                              <td> {{ item.doctor }} </td>
                              <td> {{ item.hospitalizationStartDate }} </td>
                              <td> {{ item.hospitalizationDays }} </td>
                              <td> {{ item.hospitalizationReason }} </td>
                              <td> <i *ngIf="item.id" [class]="'bx fw-bolder font-size-20 position-relative mx-1 ' + (item.hasSurgery ? 'bx-check' : 'bx-x')" style="top: 3px"> </i> </td>
                              <td> {{ item.surgeryReason }} </td>
                            </tr>
                          </tbody>
                        </table>
                      </ng-container>
                      <ng-container *ngIf="member.attachData && member.attachData.health">
                        <table>
                          <thead class="with-title"> <tr> <th colspan="100%"> <h4> {{ translatePipe.transform('ATTACH') }} </h4> </th> </tr> </thead>
                          <tbody>
                            <tr>
                              <td>
                                <div class="row gy-3">
                                  <div class="col-md-6"> <label> {{ translatePipe.transform('COUNT_UPLOADED') }} : </label> <span> {{ member.attachData.health.count }} </span> </div>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </ng-container>
                    </section>
                  </td>
                </tr>
              </tbody>
            </table>
          </section>
          <section *ngIf="hasData.EDUCATIONAL_INFO" class="gap-3 flex-column d-flex">
            <table>
              <thead class="with-title"> <tr> <th colspan="100%"> <h3> {{ translatePipe.transform('EDUCATIONAL_INFO') }} </h3> </th> </tr> </thead>
              <tbody>
                <tr>
                  <td>
                    <section class="gap-3 flex-column d-flex">
                      <div class="row gy-3">
                        <div class="col-md-6"> <label> {{ translatePipe.transform('EDUCATION_TAGS') }} : </label> <span> {{ member.educationInfo?.educationTags }} </span> </div>
                        <div class="col-md-6"> <label> {{ translatePipe.transform('TARGET_OF_STUDY') }} : </label> <span> {{ member.educationInfo?.target }} </span> </div>
                      </div>
                      <ng-container *ngIf="member.educationData && member.educationData.length">
                        <table class="table-data table-bordered table-striped w-100">
                          <thead class="with-title">
                            <tr> <th colspan="100%"> <h4> {{ translatePipe.transform('EDUCATIONAL_RECORDS') }} </h4> </th> </tr>
                            <tr>
                              <th style="width: 60px"> # </th>
                              <th> {{ translatePipe.transform('DEGREE') }} </th>
                              <th> {{ translatePipe.transform('GRADE') }} </th>
                              <th> {{ translatePipe.transform('FIELD_OF_STUDY') }} </th>
                              <th> {{ translatePipe.transform('EDUCATION_PLACE_NAME') }} </th>
                              <th> {{ translatePipe.transform('STUDY_STATUS') }} </th>
                              <th> {{ translatePipe.transform('EDUCATION_DATE') }} </th>
                              <th> {{ translatePipe.transform('EDUCATIONAL_STATUS') }} </th>
                              <th> {{ translatePipe.transform('DROPOUT_REASON') }} </th>
                              <th> {{ translatePipe.transform('AVERAGE') }} </th>
                              <th> {{ translatePipe.transform('EXEMPT_FROM_EXAM') }} </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let item of member.educationData; let index = index">
                              <td> {{ index + 1 }} </td>
                              <td> {{ item.educationDegree }} </td>
                              <td> {{ item.educationGrade }} </td>
                              <td> {{ item.educationStudyField }} </td>
                              <td> {{ item.educationPlace }} </td>
                              <td> {{ item.studyStatus }} </td>
                              <td class="multiline-cell"> {{ item.startDate }} <br/> {{ item.endDate || '---' }} </td>
                              <td> {{ item.studyingStatus }} </td>
                              <td> {{ item.dropoutReasons }} </td>
                              <td> {{ item.average }} </td>
                              <td> <i *ngIf="item.id" [class]="'bx fw-bolder font-size-20 position-relative mx-1 ' + (item.exemptFromTheExam ? 'bx-check' : 'bx-x')" style="top: 3px"> </i> </td>
                            </tr>
                          </tbody>
                        </table>
                      </ng-container>
                      <ng-container *ngIf="member.attachData && member.attachData.education">
                        <table>
                          <thead class="with-title"> <tr> <th colspan="100%"> <h4> {{ translatePipe.transform('ATTACH') }} </h4> </th> </tr> </thead>
                          <tbody>
                            <tr>
                              <td>
                                <div class="row gy-3">
                                  <div class="col-md-6"> <label> {{ translatePipe.transform('COUNT_UPLOADED') }} : </label> <span> {{ member.attachData.education.count }} </span> </div>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </ng-container>
                    </section>
                  </td>
                </tr>
              </tbody>
            </table>
          </section>
          <section *ngIf="hasData.JOB_INFO" class="gap-3 flex-column d-flex">
            <table class="table-data table-bordered table-striped w-100">
              <thead class="with-title">
                <tr> <th colspan="100%"> <h3> {{ translatePipe.transform('JOB_INFO') }} </h3> </th> </tr>
                <tr>
                  <th style="width: 60px"> # </th>
                  <th> {{ translatePipe.transform('JOB_TITLE') }} </th>
                  <th> {{ translatePipe.transform('JOB_DATE') }} </th>
                  <th> {{ translatePipe.transform('MONTHLY_INCOME') + ' (' + translatePipe.transform('IQD') + ')' }} </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of member.jobData; let index = index">
                  <td> {{ index + 1 }} </td>
                  <td> {{ item.jobTitle }} </td>
                  <td class="multiline-cell"> {{ item.startDate }} <br/> {{ item.endDate || '---' }} </td>
                  <td> {{ item.monthlyIncome }} </td>
                </tr>
              </tbody>
            </table>
          </section>
          <section *ngIf="hasData.SKILLS_INFO" class="gap-3 flex-column d-flex">
            <table class="table-data table-bordered table-striped w-100">
              <thead class="with-title">
                <tr> <th colspan="100%"> <h3> {{ translatePipe.transform('SKILLS_INFO') }} </h3> </th> </tr>
                <tr>
                  <th style="width: 60px"> # </th>
                  <th> {{ translatePipe.transform('SKILL') }} </th>
                  <th> {{ translatePipe.transform('SKILL_LEVEL') }} </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of member.skillData; let index = index">
                  <td> {{ index + 1 }} </td>
                  <td> {{ item.skill }} </td>
                  <td> {{ item.levelTitle }} </td>
                </tr>
              </tbody>
            </table>
          </section>
          <section *ngIf="hasData.ENTERTAINMENT_AND_WISHES_INFO" class="gap-3 flex-column d-flex">
            <table>
              <thead class="with-title"> <tr> <th colspan="100%"> <h3> {{ translatePipe.transform('ENTERTAINMENT_AND_WISHES_INFO') }} </h3> </th> </tr> </thead>
              <tbody>
                <tr>
                  <td>
                    <div class="row gy-3">
                      <div class="col-md-6"> <label> {{ translatePipe.transform('FAVORITES') }} : </label> <span> {{ member.otherInfo.interests_ }} </span> </div>
                      <div class="col-md-6"> <label> {{ translatePipe.transform('WISHES') }} : </label> <span> {{ member.otherInfo.wishes }} </span> </div>
                      <div class="col-md-6"> <label> {{ translatePipe.transform('HOBBIES') }} : </label> <span> {{ member.otherInfo.hobbies_ }} </span> </div>
                      <div class="col-md-6"> <label> {{ translatePipe.transform('TALENTS') }} : </label> <span> {{ member.otherInfo.talents_ }} </span> </div>
                      <div class="col-md-12"> <label> {{ translatePipe.transform('DESCRIPTION') }} : </label> <span> {{ member.otherInfo.entertainmentDescription }} </span> </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </section>
          <section *ngIf="hasData.NEEDS" class="gap-3 flex-column d-flex">
            <table>
              <thead class="with-title"> <tr> <th colspan="100%"> <h3> {{ translatePipe.transform('NEEDS') }} </h3> </th> </tr> </thead>
              <tbody>
                <tr>
                  <td>
                    <section class="gap-3 flex-column d-flex">
                      <ng-container *ngIf="member.cashNeedData && member.cashNeedData.length">
                        <table class="table-data table-bordered table-striped w-100">
                          <thead class="with-title">
                            <tr> <th colspan="100%"> <h4> {{ translatePipe.transform('CASH_NEEDS') }} </h4> </th> </tr>
                            <tr>
                              <th style="width: 60px"> # </th>
                              <th> {{ translatePipe.transform('CASH_AID_ACCOUNT_TREE') }} </th>
                              <th> {{ translatePipe.transform('AMOUNT') }} </th>
                              <th> {{ translatePipe.transform('AID_REQUEST_DATE') }} </th>
                              <th> {{ translatePipe.transform('PERSON') }} </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let item of member.cashNeedData; let index = index">
                              <td> {{ index + 1 }} </td>
                              <td> {{ item.costDonationTree }} </td>
                              <td> {{ item.amount }} </td>
                              <td> {{ item.requestDate }} </td>
                              <td> {{ item.familyMember }} </td>
                            </tr>
                          </tbody>
                        </table>
                      </ng-container>
                      <ng-container *ngIf="member.goodNeedData && member.goodNeedData.length">
                        <table class="table-data table-bordered table-striped w-100">
                          <thead class="with-title">
                            <tr> <th colspan="100%"> <h4> {{ translatePipe.transform('GOOD_NEEDS') }} </h4> </th> </tr>
                            <tr>
                              <th style="width: 60px"> # </th>
                              <th> {{ translatePipe.transform('OBJECTIVE_PRODUCT_AID') }} </th>
                              <th> {{ translatePipe.transform('QUANTITY') }} </th>
                              <th> {{ translatePipe.transform('OBJECTIVE_AID_ACCOUNT_TREE') }} </th>
                              <th> {{ translatePipe.transform('AID_REQUEST_DATE') }} </th>
                              <th> {{ translatePipe.transform('PERSON') }} </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let item of member.goodNeedData; let index = index">
                              <td> {{ index + 1 }} </td>
                              <td> {{ item.good }} </td>
                              <td> {{ item.quantity }} </td>
                              <td> {{ item.costDonationTree }} </td>
                              <td> {{ item.requestDate }} </td>
                              <td> {{ item.familyMember }} </td>
                            </tr>
                          </tbody>
                        </table>
                      </ng-container>
                      <ng-container *ngIf="member.serviceNeedData && member.serviceNeedData.length">
                        <table class="table-data table-bordered table-striped w-100">
                          <thead class="with-title">
                            <tr> <th colspan="100%"> <h4> {{ translatePipe.transform('SERVICE_NEEDS') }} </h4> </th> </tr>
                            <tr>
                              <th style="width: 60px"> # </th>
                              <th> {{ translatePipe.transform('AID_TYPE') }} </th>
                              <th> {{ translatePipe.transform('SERVICE') }} </th>
                              <th> {{ translatePipe.transform('COUNT') }} </th>
                              <th> {{ translatePipe.transform('AID_REQUEST_DATE') }} </th>
                              <th> {{ translatePipe.transform('PERSON') }} </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let item of member.serviceNeedData; let index = index">
                              <td> {{ index + 1 }} </td>
                              <td> {{ item.costDonationTree }} </td>
                              <td> {{ item.service }} </td>
                              <td> {{ item.quantity }} </td>
                              <td> {{ item.requestDate }} </td>
                              <td> {{ item.familyMember }} </td>
                            </tr>
                          </tbody>
                        </table>
                      </ng-container>
                    </section>
                  </td>
                </tr>
              </tbody>
            </table>
          </section>
          <section *ngIf="hasData.ATTACH" class="gap-3 flex-column d-flex">
            <table class="table-data table-bordered table-striped w-100">
              <thead class="with-title">
                <tr> <th colspan="100%"> <h3> {{ translatePipe.transform('ATTACH') }} </h3> </th> </tr>
                <tr>
                  <th style="width: 60px"> # </th>
                  <th> {{ translatePipe.transform('GROUP') }} </th>
                  <th> {{ translatePipe.transform('COUNT_UPLOADED') }} </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of member.attachData.all; let index = index">
                  <td> {{ index + 1 }} </td>
                  <td> {{ item.attachmentGroupTitle }} </td>
                  <td> {{ item.count }} </td>
                </tr>
              </tbody>
            </table>
          </section>
        </td>
      </tr>
    </tbody>
  </table>
</main>

<ng-template #loadingTemplate>
  <app-loader class="d-block my-4"> </app-loader>
</ng-template>
