import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { of } from 'rxjs';
import { map, concatMap, shareReplay, catchError } from 'rxjs/operators';
import { ApiService } from 'src/app/core/services/api.service';
import { ToolsService } from 'src/app/core/services/tools.service';
import { SIDEBAR_TYPE } from 'src/app/layouts/layouts.model';
import { LanguageModel } from 'src/app/core/models/public-model';
import { MENU } from 'src/app/layouts/sidebar/menu';
import { MENU_APP } from 'src/app/layouts/sidebar/menu-app';
import { MENU_PROD } from 'src/app/layouts/sidebar/menu-prod';
import { environment } from 'src/environments/environment';

interface FilterModel {
  identifier?: string;
  title?: string;
  translation?: LanguageModel[];
  viewAs?: string;
  settingArea?: number;
}

@Component({
  selector: 'app-vertical',
  templateUrl: './vertical.component.html',
  styleUrls: ['./vertical.component.scss']
})

export class VerticalComponent implements OnInit, AfterViewInit {

  langIndex: number;
  publicSettingsSubItems = [];
  familySubItems = [];
  donationSubItems = [];
  aidSubItems = [];
  warehousingSubItems = [];
  sponsorshipSubItems = [];
  hrSubItems = [];
  menuItems = JSON.parse(JSON.stringify(!environment.isApp ? (!environment.production ? MENU : MENU_PROD) : MENU_APP));
  menuLoaded = false;
  isCondensed = false;
  sidebartype: string;

  constructor(private router: Router, private apiService: ApiService, private toolsService: ToolsService) {
    this.router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        document.body.classList.remove('sidebar-enable');
      }
    }).then(() => {});
  }

  ngOnInit(): void {
    this.getDynamicSettingsItem();
    this.sidebartype = SIDEBAR_TYPE;
    this.changeSidebar(this.sidebartype);
    document.body.setAttribute('data-layout', 'vertical');
  }

  ngAfterViewInit(): void {}

  changeSidebar(value: string) {
    switch (value) {
      case 'light':
        document.body.setAttribute('data-sidebar', 'light');
        document.body.setAttribute('data-topbar', 'dark');
        document.body.removeAttribute('data-sidebar-size');
        document.body.removeAttribute('data-layout-size');
        document.body.removeAttribute('data-keep-enlarged');
        document.body.classList.remove('vertical-collpsed');
        document.body.removeAttribute('data-layout-scrollable');
        break;
      case 'compact':
        document.body.setAttribute('data-sidebar-size', 'small');
        document.body.setAttribute('data-sidebar', 'dark');
        document.body.removeAttribute('data-topbar');
        document.body.removeAttribute('data-layout-size');
        document.body.removeAttribute('data-keep-enlarged');
        document.body.classList.remove('sidebar-enable');
        document.body.classList.remove('vertical-collpsed');
        document.body.removeAttribute('data-layout-scrollable');
        break;
      case 'dark':
        document.body.setAttribute('data-sidebar', 'dark');
        document.body.removeAttribute('data-topbar');
        document.body.removeAttribute('data-layout-size');
        document.body.removeAttribute('data-keep-enlarged');
        document.body.removeAttribute('data-sidebar-size');
        document.body.classList.remove('sidebar-enable');
        document.body.classList.remove('vertical-collpsed');
        document.body.removeAttribute('data-layout-scrollable');
        break;
      case 'icon':
        document.body.classList.add('vertical-collpsed');
        document.body.setAttribute('data-sidebar', 'dark');
        document.body.removeAttribute('data-layout-size');
        document.body.setAttribute('data-keep-enlarged', 'true');
        document.body.removeAttribute('data-topbar');
        document.body.removeAttribute('data-layout-scrollable');
        break;
      case 'colored':
        document.body.classList.remove('sidebar-enable');
        document.body.classList.remove('vertical-collpsed');
        document.body.setAttribute('data-sidebar', 'colored');
        document.body.removeAttribute('data-layout-size');
        document.body.removeAttribute('data-keep-enlarged');
        document.body.removeAttribute('data-topbar');
        document.body.removeAttribute('data-layout-scrollable');
        document.body.removeAttribute('data-sidebar-size');
        break;
      default:
        document.body.setAttribute('data-sidebar', 'dark');
        break;
    }
  }

  onSettingsButtonClicked(): void {
    document.body.classList.toggle('right-bar-enabled');
  }

  onToggleMobileMenu(): void {
    this.isCondensed = !this.isCondensed;
    document.body.classList.toggle('sidebar-enable');
    document.body.classList.toggle('vertical-collpsed');
    if (window.screen.width <= 768) {
      document.body.classList.remove('vertical-collpsed');
    }
  }

  getDynamicSettingsItem(): void {
    const settingsId = 10000;
    this.apiService.post<FilterModel[]>({}, '/Family/Setting/Filter').pipe(map((res) => {
      this.langIndex = +localStorage.getItem('langId') - 1;
      this.publicSettingsSubItems = []; this.familySubItems = []; this.donationSubItems = []; this.aidSubItems = []; this.warehousingSubItems = []; this.sponsorshipSubItems = [];
      res.data.map((item) => {
        switch (item.settingArea) {
          case 0:
            this.publicSettingsSubItems.push({
              identifier: item.identifier,
              icon: 'bx-circle',
              label: item.translation[this.langIndex]?.value,
              link: '/settings/dynamic/' + item.identifier + '/' + item.viewAs,
              parentId: settingsId * 10
            });
            break;
          case 1:
            this.familySubItems.push({
              identifier: item.identifier,
              icon: 'bx-circle',
              label: item.translation[this.langIndex]?.value,
              link: '/settings/dynamic/' + item.identifier + '/' + item.viewAs,
              parentId: settingsId * 10 + 1
            });
            break;
          case 2:
            this.donationSubItems.push({
              identifier: item.identifier,
              icon: 'bx-circle',
              label: item.translation[this.langIndex]?.value,
              link: '/settings/dynamic/' + item.identifier + '/' + item.viewAs,
              parentId: settingsId * 10 + 2
            });
            break;
          case 3:
            this.aidSubItems.push({
              identifier: item.identifier,
              icon: 'bx-circle',
              label: item.translation[this.langIndex]?.value,
              link: '/settings/dynamic/' + item.identifier + '/' + item.viewAs,
              parentId: settingsId * 10 + 3
            });
            break;
          case 4:
            this.warehousingSubItems.push({
              identifier: item.identifier,
              icon: 'bx-circle',
              label: item.translation[this.langIndex]?.value,
              link: '/settings/dynamic/' + item.identifier + '/' + item.viewAs,
              parentId: settingsId * 10 + 4
            });
            break;
          case 5:
            this.sponsorshipSubItems.push({
              identifier: item.identifier,
              icon: 'bx-circle',
              label: item.translation[this.langIndex]?.value,
              link: '/settings/dynamic/' + item.identifier + '/' + item.viewAs,
              parentId: settingsId * 10 + 5
            });
            break;
        }
      });
      this.menuItems.find((item) => {
        if (item.label === 'SETTINGS') {
          const publicSettingsSubItemsOther = [
            { identifier: null, icon: 'bx-circle', label: 'DEFAULT_SETTINGS', link: '/settings/default-settings', parentId: settingsId * 10 },
            { identifier: null, icon: 'bx-circle', label: 'FAMILY_GEONAME', link: '/settings/family-geoname', parentId: settingsId * 10 },
            { identifier: null, icon: 'bx-circle', label: 'SCHOOL_GEONAME', link: '/settings/school-geoname', parentId: settingsId * 10 },
            { identifier: null, icon: 'bx-circle', label: 'PROJECT_TREE', link: '/settings/project-tree', parentId: settingsId * 10 }
          ];
          const publicSettingsSubItemsOtherLast = [
            { identifier: null, icon: 'bx-circle', label: 'TRANSLATE_LIST', link: '/settings/translate-list', parentId: settingsId * 10 }
          ];
          const donationSubItemsOther = [
            { identifier: null, icon: 'bx-circle', label: 'PERSON_DONORS', link: '/settings/person-donor-list', parentId: settingsId * 10 + 2 },
            { identifier: null, icon: 'bx-circle', label: 'INSTITUTE_DONORS', link: '/settings/institute-donor-list', parentId: settingsId * 10 + 2 }
          ];
          const warehousingSubItemsOther = [
            { identifier: null, icon: 'bx-circle', label: 'CASH_TREE', link: '/settings/cash-tree', parentId: settingsId * 10 + 4 },
            { identifier: null, icon: 'bx-circle', label: 'PRODUCT_TREE', link: '/settings/product-tree', parentId: settingsId * 10 + 4 },
            { identifier: null, icon: 'bx-circle', label: 'SERVICE_TREE', link: '/settings/service-tree', parentId: settingsId * 10 + 4 }
          ];
          const publicSettingsSubItems = { id: settingsId * 10, icon: 'bx-circle', label: 'PUBLIC_SETTINGS', parentId: settingsId, subItems: [...publicSettingsSubItemsOther, ...this.publicSettingsSubItems, ...publicSettingsSubItemsOtherLast] };
          const familySubItems = { id: settingsId * 10 + 1, icon: 'bx-circle', label: 'FAMILY', parentId: settingsId, subItems: this.familySubItems };
          const donationSubItems = { id: settingsId * 10 + 2, icon: 'bx-circle', label: 'DONATION', parentId: settingsId, subItems: [...donationSubItemsOther, ...this.donationSubItems] };
          const aidSubItems = { id: settingsId * 10 + 3, icon: 'bx-circle', label: 'AID', parentId: settingsId, subItems: this.aidSubItems };
          const warehousingSubItems = { id: settingsId * 10 + 4, icon: 'bx-circle', label: 'WAREHOUSING', parentId: settingsId, subItems: [...warehousingSubItemsOther, ...this.warehousingSubItems] };
          const sponsorshipSubItems = { id: settingsId * 10 + 5, icon: 'bx-circle', label: 'SPONSORSHIP', parentId: settingsId, subItems: this.sponsorshipSubItems };
          item.subItems = [];
          item.subItems.push(publicSettingsSubItems, familySubItems, donationSubItems, aidSubItems, warehousingSubItems, sponsorshipSubItems);
        }
      });
    }), shareReplay(), concatMap(() => this.getDynamicSettingsItemInHr()), catchError((err) => {
      this.menuLoaded = true;
      return of(err);
    })).subscribe();
  }

  getDynamicSettingsItemInHr() {
    const settingsId = 103000;
    return this.apiService.post<FilterModel[]>({}, '/Hr/Setting/Filter').pipe(map((res) => {
      this.langIndex = +localStorage.getItem('langId') - 1;
      this.hrSubItems = [];
      res.data.map((item) => {
        switch (item.settingArea) {
          case 0:
            this.hrSubItems.push({
              identifier: item.identifier,
              icon: 'bx-circle',
              label: item.translation[this.langIndex]?.value,
              link: '/hr/settings/dynamic/' + item.identifier + '/' + item.viewAs,
              parentId: settingsId * 10
            });
            break;
        }
      });
      this.menuItems.find((items) => {
        if (items.label === 'HR') {
          items.subItems.find((item: any) => {
            if (item.label === 'SETTINGS') {
              const hrSubItemsOther = [
                { identifier: null, icon: 'bx-circle', label: 'DEFAULT_SETTINGS', link: '/hr/settings/default-settings', parentId: settingsId * 10 },
                { identifier: null, icon: 'bx-circle', label: 'JOB_CLASSIFICATION', link: '/hr/settings/job-classification', parentId: settingsId * 10 },
                { identifier: null, icon: 'bx-circle', label: 'ORGANIZATIONAL_CHART', link: '/hr/settings/organizational-chart', parentId: settingsId * 10 }
              ];
              const hrSubItemsOtherLast = [
                { identifier: null, icon: 'bx-circle', label: 'CREATE_TEAM', link: '/hr/settings/team/create', parentId: settingsId * 10 },
                { identifier: null, icon: 'bx-circle', label: 'TEAM_LIST', link: '/hr/settings/team/list', parentId: settingsId * 10 }
              ];
              item.subItems = [];
              item.subItems.push(...hrSubItemsOther, ...this.hrSubItems, ...hrSubItemsOtherLast);
            }
          });
        }
      });
      const userPermissions = this.toolsService.getPermissions().split(', ');
      this.menuItems.map((item) => {
        if (item.subItems && item.subItems.length) {
          item.subItems = item.subItems.filter((subItem: any) => {
            if (subItem.link) {
              return userPermissions.find((t: string) => subItem.link.includes(t.trim()));
            }
            else if (subItem.subItems) {
              return subItem.subItems = subItem.subItems.filter((subSubItem: any) => userPermissions.find((t: string) => subSubItem.link.includes(t.trim())));
            }
          });
        }
      });
      this.menuItems = this.menuItems.filter((item) => {
        if (item.subItems && item.subItems.length) {
          item.subItems = item.subItems.filter((subItem: any) => subItem.link || (subItem.subItems && subItem.subItems.length));
        }
        return item.link || (item.subItems && item.subItems.length);
      });
      this.menuLoaded = true;
    }), shareReplay(), catchError((err) => {
      this.menuLoaded = true;
      return of(err);
    }));
  }

}
