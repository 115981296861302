import { Component, OnInit, AfterContentInit, Input } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';

@Component({
  selector: 'app-group-field',
  templateUrl: './group-field.component.html',
  styleUrls: ['./group-field.component.scss']
})

export class GroupFieldComponent implements OnInit, AfterContentInit {

  formG: FormGroup;
  @Input() form: FormGroupDirective;
  @Input() label: string;
  @Input() primaryCtrl: string;
  @Input() primaryLbl: string;
  @Input() secondaryCtrl: string;
  @Input() secondaryLbl: string;
  @Input() required: boolean;
  @Input() disabled: boolean;

  constructor() {
    this.label = this.primaryCtrl = this.primaryLbl = this.secondaryCtrl = this.secondaryLbl = '';
    this.required = this.disabled = false;
  }

  ngOnInit(): void {}

  ngAfterContentInit(): void {
    if (this.form) {
      this.formG = this.form.form;
    }
  }

  onBlur(ctrl: string): void {
    const field = this[ctrl];
    const value = this.formG.value[field].replace(/\s+/g, ' ');
    this.formG.controls[field].setValue(value);
  }

  getErrorText(): string {
    const ctrl_1 = this.formG.controls[this.primaryCtrl];
    const ctrl_2 = this.formG.controls[this.secondaryCtrl];
    if (ctrl_1.errors?.required || ctrl_1.errors?.whitespace || ctrl_2.errors?.required || ctrl_2.errors?.whitespace) {
      return 'IS_REQUIRED';
    }
  }

}
