export const PAY_STATUS = [
  {
    title: "THE_DEBTOR",
    color: "yellow",
  },
  {
    title: "CLEARED",
    color: "green",
  },
  {
    title: "STOP_REQUEST",
    color: "orange",
  },
  {
    title: "WITHDRAWAL_CONFIRMATION",
    color: "red",
  },
];

export const PAY_STATUS_2 = [
  {
    title: "NOT_REPAID",
    color: "red",
  },
  {
    title: "REPAID",
    color: "green",
  },
];

export const REQUEST_STATUS = [
  {
    title: "AWAITING_DISCOVERY_MANAGER_REVIEW",
    color: "yellow",
  },
  {
    title: "AWAITING_DISCOVERY",
    color: "orange",
  },
  {
    title: "AWAITING_AUDIT",
    color: "red",
  },
  {
    title: "AWAITING_CONFIRM_MANAGER",
    color: "light-green",
  },
  {
    title: "FINAL_CONFIRMATION",
    color: "green",
  },
];

export const WEIGHT_UNIT = [
  {
    id: 1,
    name: "KILOGRAM",
  },
];
export const LENGTH_UNIT = [
  {
    id: 1,
    name: "CENTIMETER",
  },
];

export const Grades = [
  { value: 1 },
  { value: 2 },
  { value: 3 },
  { value: 4 },
  { value: 5 },
  { value: 6 },
  { value: 7 },
  { value: 8 },
  { value: 9 },
  { value: 10 },
];

export const SETTING_IDS = {
  cashTree: "9a01433e-f36b-1410-8610-002c3b3755cf",
  familyTags: "9484433e-f36b-1410-8418-00a83613e188",
  deathReasons: "470021bc-f939-469b-8f2f-f18bd70a2a56",
  beneficiaryTypes: "ab0bb846-415d-472c-b13b-41a54c530e1a",
  festivalTypes: "b790d16c-c16b-44bb-87d4-e82f4857babf",
  educationTags: "2f4ff593-232b-4eaf-a335-c8f486d08d7e",
  educationDegrees: "feee9ecd-bcde-44bb-8210-b6c290dbbcba",
  festivals: "6cff463e-f36b-1410-83f2-00dbfe4833f6",
  userPrefixes: "4200473e-f36b-1410-83f2-00dbfe4833f6",
  institutes: "0001473e-f36b-1410-83f2-00dbfe4833f6",
  institutesTree: "cedf87a6-72d3-4532-82e9-910e7f360cf7",
  returnAidReasons: "1001473e-f36b-1410-83f2-00dbfe4833f6",
  educationGrades: "698f8250-1c5c-4cb0-ae28-535b72d80b92",
  fieldOfStudy: "793c913b-963e-4e57-a064-83cb779e8bb2",
  fieldOfStudyTree: "9aa8c46e-94bd-4a7a-ba6e-79ea7b3f4d5c",
  lesson: "dcafc030-9169-4675-823a-3736ffdbf5ad",
  warehouses: "f878433e-f36b-1410-8f6b-0010f6ea7cd1",
  campaignTypes: "18cf7a92-5731-4733-abdf-9f537cc1c689",
  nonSupplyGoodsReason: "e307433e-f36b-1410-83eb-0071b95db24c",
  callNumberTypes: "f407433e-f36b-1410-83eb-0071b95db24c",
  socialNetworkTypes: "fa07433e-f36b-1410-83eb-0071b95db24b",
  campaigns: "6bff463e-f36b-1410-83f2-00dbfe4833f6",
  educationPlaceTypes: "7a8e1f8a-0c78-499a-86aa-5f60828b1be6",
  educationPlace: "04a85290-2aea-452a-9e13-dbd03fbbe445",
  educationPlaceTree: "8bc8abe7-d16f-4304-ae02-9fca679c2c01",
  units: "c459433e-f36b-1410-8841-0053e1441b0e",
  productGroup: "0a08433e-f36b-1410-83eb-0071b95db24c",
  products: "c959433e-f36b-1410-8841-0053e1441b0e",
  cancelReasons: "c5ff463e-f36b-1410-83f2-00dbfe4833f6",
  dropoutReasons: "537e8bbc-7c1d-42a1-a6f2-f6e10af5ba61",
  assetTypes: "feb34bfc-3c0f-4fca-bafd-d0e37192738e",
  assetTypesTree: "97ebd19a-f2b5-40ad-a05e-993803b429dc",
  serviceGroup: "dc07433e-f36b-1410-83eb-0071b95db24c",
  returnReasons: "e4a3af49-925b-4429-b467-0fc98a0c1873",
  healthTags: "89d8423e-f36b-1410-8bf5-0039f231dc86",
  service: "dc07433e-f36b-1410-83eb-0071b95db24b",
  illnesses: "fa9d77ef-faf0-44f7-9349-b076a7f20c1c",
  hospitals: "a88dc24b-4736-4663-af8d-09bc1c3574f8",
  hospitalsTree: "23905a81-a4e8-48a6-818f-c45368b6ad59",
  departmentAndCenter: "02251fc6-a3f8-4c36-86ad-7a9cd26238dc",
  costTypes: "2f7b504a-7c10-49a4-a766-2fdf65b7d2fe",
  skills: "2293cc30-acbe-4c62-b991-7f611fc5b5ba",
  wishes: "a27be37a-d1b6-4e37-b618-578ce83d697e",
  interests: "7c4c3952-80fc-4a58-afa7-486398d2da5a",
  jobs: "c6ff463e-f36b-1410-83f2-00dbfe4833f6",
  jobsTree: "17d65492-1c73-4cab-ab17-9cd8e2f6870d",
  attachmentTypes: "2700473e-f36b-1410-83f2-00dbfe4833f6",
  buildingTypes: "2b00473e-f36b-1410-83f2-00dbfe4833f6",
  relatives: "2e00473e-f36b-1410-83f2-00dbfe4833f6",
  mukhtar: "0501473e-f36b-1410-83f2-00dbfe4833f6",
  familyRelationTypes: "1801473e-f36b-1410-83f2-00dbfe4833f6",
  ownershipTypes: "2eb58129-6a84-4545-b43f-45606cf3b500",
  donationTree: "e9444d27-e599-497f-acef-a3fa25de6c2e",
  aidCash: "46be9f3d-610b-ee11-83ac-b4c2f69af424",
  donationCash: "a302c0d3-ebc2-4466-b73e-fd673579531e",
  aidGood: "6f07534a-610b-ee11-83ac-b4c2F69af424",
  aidService: "86ff463e-f36b-1410-83f2-00dbfe4833f6",
  reasonForEditingSponsorship: "3de3423e-f36b-1410-8542-000d292dba07",
  reasonForStopSponsorship: "36e3423e-f36b-1410-8542-000d292dba07",
  salaryPeriod: "4de3423e-f36b-1410-8542-000d292dba07",
  lackOfInclusionReason: "4633433e-f36b-1410-8d04-00513abe0d5d",
  deathTypes: "c9f0b3d1-f7a3-4ef7-aeb4-649458c27ac0",
  hobbies: "542cd559-8005-4279-80c2-c1c8eff56f2c",
  talents: "6a0bf030-5259-4352-9b68-369e17d0328b",
  identificationDocuments: "2a69433e-f36b-1410-8011-00c680bf0aba",
};

export const HR_SETTING_IDS = {
  attachmentTypes: "d0fc423e-f36b-1410-8737-00f0abbec0c2",
  jobTags: "d2fc423e-f36b-1410-8737-00f0abbec0c2",
  personnelSkillTags: "9401433e-f36b-1410-8610-002c3b3755cf",
  skills: "9501433e-f36b-1410-8610-002c3b3755cf",
  salaryFactors: "9901433e-f36b-1410-8610-002c3b3755cf",
  endOfCooperationReasons: "f7c53ab0-bb95-4635-8fa5-7ff768df2efd",
  educationDegrees: "cafc423e-f36b-1410-8737-00f0abbec0c2",
  fieldOfStudy: "cbfc423e-f36b-1410-8737-00f0abbec0c2",
  certificates: "ccfc423e-f36b-1410-8737-00f0abbec0c2",
  salaryPeriod: "3455d23c-f6bc-42b3-b779-04b68e0eb6df",
  contractTypes: "c4fc423e-f36b-1410-8737-00f0abbec0c2",
  rulesType: "affc423e-f36b-1410-8737-00f0abbec0c2",
  loanTypes: "c1fc423e-f36b-1410-8737-00f0abbec0c2",
  fineReasons: "b5fc423e-f36b-1410-8737-00f0abbec0c2",
  rewardsReasons: "bbfc423e-f36b-1410-8737-00f0abbec0c2",
  employmentType: "a8fc423e-f36b-1410-8737-00f0abbec0c2",
};
