import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ToolsService } from 'src/app/core/services/tools.service';

@Component({
  selector: 'app-filter-card',
  templateUrl: './filter-card.component.html',
  styleUrls: ['./filter-card.component.scss']
})

export class FilterCardComponent implements OnInit {

  filterAsideOpened = false;
  filterAsidePined = false;
  @Input() classList = '';
  @Output() removeFilters = new EventEmitter();

  constructor(private toolsService: ToolsService) { }

  ngOnInit(): void { }

  onClickPinBtn(): void {
    this.toolsService.setColWidth();
    this.filterAsidePined = !this.filterAsidePined;
  }

}
