import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/core/services/api.service';

@Injectable({ providedIn: 'root' })

export class AccountService {

  constructor(private api: ApiService) { }

  accountApiCreateDonor<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/AccountApi/CreateDonor', 'account');
  }

  accountApiCreateSponsorship<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/AccountApi/CreateSponsorship', 'account');
  }

  accountApiCreateReffer<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/AccountApi/CreateReffer', 'account');
  }

  accountApiCreateEmployee<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/AccountApi/CreateEmployee', 'account');
  }

  accountApiCreateFamilyMember<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/AccountApi/CreateFamilyMember', 'account');
  }

  accountApiDeleteDonor<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/AccountApi/DeleteDonor', 'account');
  }

  accountApiCreateOTP<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/AccountApi/CreateOTP', 'account');
  }

  accountApiValidateOTP<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/AccountApi/ValidateOTP', 'account');
  }

  accountApiCheckOTPAndCreateUser<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/AccountApi/CheckOTPAndCreateUser', 'account');
  }

  accountApiPhoneExist<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/AccountApi/PhoneExist', 'account');
  }

  catalogsGetAncestry<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/Catalogs/GetAncestry/${ query }`, 'account');
  }

  catalogsGetMaritalStatus<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/Catalogs/GetMaritalStatus/${ query }`, 'account');
  }

  catalogsGetGender<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/Catalogs/GetGender/${ query }`, 'account');
  }

  catalogsGetRoles<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/Catalogs/GetRoles/${ query }`, 'account');
  }

  jobCreate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Job/Create', 'account');
  }

  jobUpdate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Job/Update', 'account');
  }

  jobDelete<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Job/Delete', 'account');
  }

  jobFilter<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Job/Filter', 'account');
  }

  jobGetByIds<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Job/GetByIds', 'account');
  }

  permissionCreate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Permission/Create', 'account');
  }

  permissionDelete<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.delete(data, '/Permission/Delete', 'account');
  }

  permissionUpdate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.put(data, '/Permission/Update', 'account');
  }

  permissionFilter<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Permission/Filter', 'account');
  }

  roleCreate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Role/Create', 'account');
  }

  roleDelete<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.delete(data, '/Role/Delete', 'account');
  }

  roleUpdate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.put(data, '/Role/Update', 'account');
  }

  roleDeletePermissions<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.delete(data, '/Role/DeletePermissions', 'account');
  }

  roleAddPermissions<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Role/AddPermissions', 'account');
  }

  roleFilter<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/Role/Filter', 'account');
  }

  roleGet<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/Role/Get/${ query }`, 'account');
  }

  userCreate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/User/Create', 'account');
  }

  userDelete<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/User/Delete', 'account');
  }

  userSetPermissionPolicy<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.put(data, '/User/SetPermissionPolicy', 'account');
  }

  userGetByID<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/User/GetByID/${ query }`, 'account');
  }

  userGetByIdentifier<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/User/GetByIdentifier/${ query }`, 'account');
  }

  userUpdateProfile<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/User/UpdateProfile', 'account');
  }

  userUpdatePhoneAndEmail<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/User/UpdatePhoneAndEmail', 'account');
  }

  userSetPassword<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/User/SetPassword', 'account');
  }

  userAddAddress<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/User/AddAddress', 'account');
  }

  userUpdateAddress<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/User/UpdateAddress', 'account');
  }

  userDeleteAddress<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/User/DeleteAddress', 'account');
  }

  userGetAddresses<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/User/GetAddresses/${ query }`, 'account');
  }

  userGetAddressByIdentifier<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/User/GetAddressByIdentifier/${ query }`, 'account');
  }

  userFilter<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/User/Filter', 'account');
  }

  userGetByIdentifiers<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/User/GetByIdentifiers', 'account');
  }

  userGetUserImageByIdentifiers<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/User/GetUserImageByIdentifiers', 'account');
  }

  userUserExists<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/User/UserExists', 'account');
  }

  userAddToBranch<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/User/AddToBranch', 'account');
  }

  userRemoveFromBranch<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.delete(data, '/User/RemoveFromBranch', 'account');
  }

  userGetBranches<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/User/GetBranches/${ query }`, 'account');
  }

  userAddPermission<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/User/AddPermission', 'account');
  }

  userRemovePermission<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.delete(data, '/User/RemovePermission', 'account');
  }

  userGetPermissions<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/User/GetPermissions/${ query }`, 'account');
  }

  userGetAllPermission<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/User/GetAllPermission/${ query }`, 'account');
  }

  userFilterEmployees<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/User/FilterEmployees', 'account');
  }

  userCallNumberCreate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/UserCallNumber/Create', 'account');
  }

  userCallNumberUpdate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/UserCallNumber/Update', 'account');
  }

  userCallNumberDelete<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/UserCallNumber/Delete', 'account');
  }

  userCallNumberGetByUserIdentifier<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/UserCallNumber/GetByUserIdentifier/${ query }`, 'account');
  }

  userRoleCreate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/UserRole/Create', 'account');
  }

  userRoleDelete<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/UserRole/Delete', 'account');
  }

  userRoleGetByUserIdentifier<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/userRole/GetByUserIdentifier/${ query }`, 'account');
  }

  userSocialNetworkCreate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/UserSocialNetwork/Create', 'account');
  }

  userSocialNetworkUpdate<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/UserSocialNetwork/Update', 'account');
  }

  userSocialNetworkDelete<T>(data: {}): Observable<{ data: T; total?: number }> {
    return this.api.post(data, '/UserSocialNetwork/Delete', 'account');
  }

  userSocialNetworkGetByUserIdentifier<T>(data: {}, query: number | string = ''): Observable<{ data: T; total?: number }> {
    return this.api.get(data, `/UserSocialNetwork/GetByUserIdentifier/${ query }`, 'account');
  }

}
