import { Component, OnInit, OnChanges, SimpleChanges, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { FileModel, PublicModel } from 'src/app/core/models/public-model';

@Component({
  selector: 'app-file-box',
  templateUrl: './file-box.component.html',
  styleUrls: ['./file-box.component.scss']
})

export class FileBoxComponent implements OnInit, OnChanges {

  env = environment.api;
  modalRef: NgbModalRef;
  reader = new FileReader();
  afterInit = false;
  attachments = {};
  selectedType: number;
  fileSelected: any;
  @Input() files: Array<FileModel> = [];
  @Input() newFile: FileModel;
  @Input() remove: FileModel;
  @Input() attachmentGroup: number;
  @Input() attachmentTypes: PublicModel[];
  @Input() loading = false;
  @Input() disabled = false;
  @Output() selectFile = new EventEmitter();
  @Output() removeFile = new EventEmitter();
  @ViewChild('confirmModal') confirmModal: ElementRef;
  @ViewChild('profileImage') profileImage: ElementRef;

  constructor(private modal: NgbModal) {}

  ngOnInit(): void {
    this.afterInit = true;
    this.attachmentTypes = this.attachmentTypes.filter((item) => item['group'] === this.attachmentGroup);
    this.setAttachments();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.files && this.afterInit) {
      this.setAttachments();
    }
    if (changes.newFile && this.afterInit && this.newFile.id) {
      this.attachments[this.newFile.attachmentTypeId].push(this.onFileChecker(this.newFile));
    }
    if (changes.remove && this.afterInit && this.remove.id) {
      this.attachments[this.remove.attachmentTypeId].splice(
        this.attachments[this.remove.attachmentTypeId].findIndex((item: any) => item.id === this.remove.id), 1
      );
    }
  }

  openModal(content: any, size = 'lg'): void {
    this.modalRef = this.modal.open(content, { size: size, centered: true });
    this.modalRef.dismissed.pipe(map(() => {})).subscribe();
  }

  onFileChecker(data: FileModel): FileModel {
    const isImage = ['jpg', 'jpeg', 'png', 'jfif', 'gif'].includes(data.fileUrl.split('.')[1]);
    const filePreviewUrl = isImage ? data.fileUrl : '20/8a/208a7870-2c94-466f-5743-08dbf89e51da.png'
    return { ...data, filePreviewUrl, isImage };
  }

  setAttachments() {
    if (this.attachmentTypes.length === 0) {
      return;
    }
    this.attachmentTypes.forEach((item) => (this.attachments[item.id] = []));
    this.files.forEach((item) => {
      if (!this.attachments[item.attachmentTypeId]) {
        return;
      }
      this.attachments[item.attachmentTypeId].push(this.onFileChecker(item));
    });
  }

  onSelectFile(event: Event, typeId: number): void {
    const formData = new FormData();
    const file = (event.target as HTMLInputElement).files[0];
    const rand = Math.floor(Math.random() * 1000) + 1;
    formData.append('file', file, rand + file.name);
    this.selectedType = typeId;
    this.selectFile.emit({ file: formData, typeId });
    this.profileImage.nativeElement.value = '';
  }

  onRemoveImage(file: any): void {
    if (this.disabled) {
      return;
    }
    this.modal.open(this.confirmModal, { size: 'md', centered: true }).dismissed.pipe(map((res) => {
      if (res === 'confirm') {
        this.profileImage.nativeElement.value = '';
        this.removeFile.emit(file);
      }
      else {
        return of([]);
      }
    })).subscribe();
  }

}
